import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import React, { useEffect, useState, useContext } from 'react'
import camelcaseKeys from 'camelcase-keys'
import axios from 'axios'
import { SnackbarContext } from '../../../context'

import {
	Box,
	Typography,
	Breadcrumbs,
	makeStyles,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Container,
	Paper
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import TextField from '@material-ui/core/TextField'
import { API_URL, API_MerchatList } from '../../../config/constants'
import { useNavigate } from 'react-router'
import { formatEuro } from '../../../utils/formatCurrency'

const useStyles = makeStyles((theme) => ({
	link: {
		fontSize: '0.875rem',
		fontFamily:
			'-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
		fontWeight: 500,
		lineHeight: 1.57,
		color: 'grey'
	},
	root: {
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '25ch'
		}
	},
	addContent: {
		marginTop: theme.spacing(3)
	},
	h100: {
		height: '100%'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		width: '100%'
	}
}))

const MerchantAdd = () => {
	const classes = useStyles()
	const params = useParams()
	const { showMessage } = useContext(SnackbarContext)
	const { t } = useTranslation()

	const navigate = useNavigate()

	const [formData, setFormData] = useState({
		id: null,
		activateCustomTheme: false,
		ageLimitForUnconfirmedUsers: 25,
		bic: '',
		billingCity: '',
		billingCountryCode: 'DE',
		billingPcode: '',
		billingStreet: '',
		billingStreetSecondRow: '',
		businessId: '',
		confirmationRequired: '',
		contactLanguage: 'DE',
		country: 'DE',
		creditLine: '',
		currency: 'EUR',
		deactivated: false,
		email: '',
		enableOrgLists: false,
		expectedRevenue: '',
		riskLevel: '2',
		iban: '',
		invoiceWithinWorkDays: true,
		invoicingDelay: '1',
		timezone: 'Central European Time',
		invoicingFee: 0,
		mobile: '',
		name: '',
		noPersonCreditRestrictions: false,
		paymentPeriod: '30',
		pendingLimit: '0',
		preventInvoicing: false,
		preventPending: false,
		regOffice: '',
		registrationNumber: '',
		sendInvoiceReminders: true,
		shouldRestrictOptionalFields: true,
		shouldSendAsGrossInvoice: false,
		tebaMerchantId: '',
		unconfirmedUserPrivilegesRestrictions: false,
		shouldReceiveInvoices: false,
		vatId: '',
		website: '',
		timer: '20000',
		invoicingEmail: '',
		amountMismatchTolerance: ''
	})
	const [creditLine, setCreditLine] = useState('')
	const [amountMismatchTolerance, setAmountMismatchTolerance] = useState('')

	useEffect(() => {
		const load = async () => {
			try {
				const { data } = await axios.get(`${API_URL}${API_MerchatList}/${params.id}`)

				let detailData = camelcaseKeys(data, { deep: true })
				delete detailData.updatedAt
				delete detailData.createdAt
				delete detailData.timer
				delete detailData.timezone

				setFormData({
					...detailData,
					creditLine: detailData.creditLine ? detailData.creditLine / 100 : ''
				})
			} catch (error) {
				console.log('loading error', error)
			}
		}

		if (params.id) {
			load()
		}
	}, [params.id])

	useEffect(() => {
		if (!creditLine) setCreditLineValue()
		if (!amountMismatchTolerance) setAmountMismatchToleranceValue()
	}, [formData])

	const setCreditLineValue = (e) => {
		if (e?.type === 'focus') {
			setCreditLine(formData.creditLine)
		} else {
			// blur
			setCreditLine(formatEuro(formData.creditLine))
		}
	}

	const setAmountMismatchToleranceValue = (e) => {
		if (e?.type === 'focus') {
			setAmountMismatchTolerance(formData.amountMismatchTolerance || '')
		} else {
			// blur
			setAmountMismatchTolerance(
				formData.amountMismatchTolerance
					? formatEuro(formData.amountMismatchTolerance / 100)
					: ''
			)
		}
	}

	const handleChangeFormData = (e, prop) => {
		let value = e.target.value
		if (prop === 'creditLine') {
			value = value.replace(/[^\d.]/g, '')
			setCreditLine(value)
		} else if (prop === 'amountMismatchTolerance') {
			value = value.replace(/[^\d.]/g, '')
			setAmountMismatchTolerance(value)
		}

		setFormData({ ...formData, [prop]: value })
	}

	const saveMerchant = async (e) => {
		e.preventDefault()

		const tmp = {
			...formData,
			creditLine: formData.creditLine ? formData.creditLine * 100 : ''
		}

		try {
			let id
			if (params.id) {
				await axios.put(`${API_URL}${API_MerchatList}/${params.id}`, tmp)
				id = params.id
			} else {
				const { data } = await axios.post(`${API_URL}${API_MerchatList}`, tmp)
				id = data.id
			}

			navigate(`/merchants/detail/${id}`)
		} catch (e) {
			showMessage(t('errors.500'), 'error')
		}
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					Merchant {!params.id ? 'Add' : 'Edit'}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					Dashboard
				</Link>
				<Link
					className={classes.link}
					color="inherit"
					variant={'subtitle2'}
					to="/merchants"
				>
					Merchants
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{!params.id ? 'Add' : 'Edit'}
				</Typography>
			</Breadcrumbs>

			<Container maxWidth={'md'}>
				<Box mt={4} component={'form'} onSubmit={saveMerchant}>
					<Box mb={4}>
						<Paper variant="outlined" square>
							<Box p={2}>
								<Typography variant="h4" component="h4" gutterBottom={true}>
									Basic details
								</Typography>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'name')}
										label="Merchant Name"
										value={formData.name}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'vatId')}
										label="Vat ID"
										value={formData.vatId}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										onChange={(e) =>
											handleChangeFormData(e, 'registrationNumber')
										}
										label="Registration Number"
										value={formData.registrationNumber}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										onChange={(e) => handleChangeFormData(e, 'regOffice')}
										label="Reg Office"
										value={formData.regOffice}
									/>
								</Box>
								<Box mt={1}>
									<TextField
										fullWidth
										onChange={(e) => handleChangeFormData(e, 'businessId')}
										label="Crefo ID"
										value={formData.businessId}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'tebaMerchantId')}
										label="Tebapay Merchant ID"
										value={formData.tebaMerchantId}
									/>
								</Box>
							</Box>
						</Paper>
					</Box>

					<Box mb={4}>
						<Paper variant="outlined" square>
							<Box p={2}>
								<Typography variant="h4" component="h4" gutterBottom={true}>
									Contact details
								</Typography>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'billingCity')}
										label="Billing City"
										value={formData.billingCity}
									/>
								</Box>

								<Box mt={1}>
									<FormControl className={classes.formControl}>
										<InputLabel shrink id="billing-country-code-select">
											Billing Country Code
										</InputLabel>
										<Select
											fullWidth
											labelId="billing-country-code-select"
											value={formData.billingCountryCode}
											onChange={(e) =>
												handleChangeFormData(e, 'billingCountryCode')
											}
											displayEmpty
											className={classes.selectEmpty}
										>
											<MenuItem value="DE">DE</MenuItem>
											<MenuItem value="AT">AT</MenuItem>
										</Select>
									</FormControl>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'billingPcode')}
										label="Billing PostCode"
										value={formData.billingPcode}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'billingStreet')}
										label="Billing Street"
										value={formData.billingStreet}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										onChange={(e) =>
											handleChangeFormData(e, 'billingStreetSecondRow')
										}
										label="Billing Second Street "
										value={formData.billingStreetSecondRow}
									/>
								</Box>

								<Box mt={1}>
									<FormControl className={classes.formControl}>
										<InputLabel shrink id="country">
											Country
										</InputLabel>
										<Select
											fullWidth
											labelId="Country"
											value={formData.country}
											onChange={(e) => handleChangeFormData(e, 'country')}
											displayEmpty
											className={classes.selectEmpty}
										>
											<MenuItem value="DE">Germany</MenuItem>
											<MenuItem value="AT">Austria</MenuItem>
										</Select>
									</FormControl>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										onChange={(e) => handleChangeFormData(e, 'mobile')}
										label="Mobile Number"
										value={formData.mobile}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										onChange={(e) => handleChangeFormData(e, 'website')}
										label="Website"
										value={formData.website}
									/>
								</Box>
								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'email')}
										label="Email"
										value={formData.email}
									/>
								</Box>
								<Box mt={1}>
									<TextField
										fullWidth
										onChange={(e) => handleChangeFormData(e, 'invoicingEmail')}
										label="Invoicing email"
										value={formData.invoicingEmail}
									/>
								</Box>
							</Box>
						</Paper>
					</Box>

					<Box mb={4}>
						<Paper variant="outlined" square>
							<Box p={2}>
								<Typography variant="h4" component="h4" gutterBottom={true}>
									Credit line
								</Typography>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										type={'text'}
										onChange={(e) => handleChangeFormData(e, 'creditLine')}
										onFocus={setCreditLineValue}
										onBlur={setCreditLineValue}
										label="Credit Line"
										value={creditLine}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										type={'number'}
										onChange={(e) => handleChangeFormData(e, 'expectedRevenue')}
										label="Expected Revenue"
										value={formData.expectedRevenue}
									/>
								</Box>
							</Box>
						</Paper>
					</Box>

					<Box mb={4}>
						<Paper variant="outlined" square>
							<Box p={2}>
								<Typography variant="h4" component="h4" gutterBottom={true}>
									Banking
								</Typography>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'iban')}
										label="IBAN"
										value={formData.iban}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										onChange={(e) => handleChangeFormData(e, 'bic')}
										label="BIC"
										value={formData.bic}
									/>
								</Box>
							</Box>
						</Paper>
					</Box>

					<Box mb={4}>
						<Paper variant="outlined" square>
							<Box p={2}>
								<Typography variant="h4" component="h4" gutterBottom={true}>
									Settings
								</Typography>

								<Box mt={1}>
									<FormControl className={classes.formControl}>
										<InputLabel shrink id="risk-level-select">
											Risk Level
										</InputLabel>
										<Select
											fullWidth
											labelId="risk-level-select"
											value={formData.riskLevel}
											onChange={(e) => handleChangeFormData(e, 'riskLevel')}
											displayEmpty
											className={classes.selectEmpty}
										>
											<MenuItem value={1}>{t('risks.low')}</MenuItem>
											<MenuItem value={2}>{t('risks.neutral')}</MenuItem>
											<MenuItem value={3}>{t('risks.high')}</MenuItem>
										</Select>
									</FormControl>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										type={'number'}
										onChange={(e) => handleChangeFormData(e, 'invoicingDelay')}
										label="Invoice Delay"
										value={formData.invoicingDelay}
									/>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										required
										type={'number'}
										onChange={(e) => handleChangeFormData(e, 'paymentPeriod')}
										label="Payment Period"
										value={formData.paymentPeriod}
									/>
								</Box>

								<Box mt={1}>
									<FormControl className={classes.formControl}>
										<InputLabel shrink id="send-invoice-reminders-select">
											Send invoice reminders
										</InputLabel>
										<Select
											fullWidth
											labelId="send-invoice-reminders-select"
											value={formData.sendInvoiceReminders}
											onChange={(e) =>
												handleChangeFormData(e, 'sendInvoiceReminders')
											}
											displayEmpty
											className={classes.selectEmpty}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</Select>
									</FormControl>
								</Box>

								<Box mt={1}>
									<FormControl className={classes.formControl}>
										<InputLabel shrink id="prevent-invoicing-select">
											Prevent Invoicing
										</InputLabel>
										<Select
											fullWidth
											labelId="prevent-invoicing-select"
											value={formData.preventInvoicing}
											onChange={(e) =>
												handleChangeFormData(e, 'preventInvoicing')
											}
											displayEmpty
											className={classes.selectEmpty}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</Select>
									</FormControl>
								</Box>

								<Box mt={1}>
									<FormControl className={classes.formControl}>
										<InputLabel shrink id="restric-optional-field-select">
											Restrict Optional Field
										</InputLabel>
										<Select
											fullWidth
											labelId="restric-optional-field-select"
											value={formData.shouldRestrictOptionalFields}
											onChange={(e) =>
												handleChangeFormData(
													e,
													'shouldRestrictOptionalFields'
												)
											}
											displayEmpty
											className={classes.selectEmpty}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</Select>
									</FormControl>
								</Box>

								<Box mt={1}>
									<FormControl className={classes.formControl}>
										<InputLabel shrink id="restric-optional-field-select">
											Disable person credit decision
										</InputLabel>
										<Select
											fullWidth
											labelId="restric-person-credit-select"
											value={formData.noPersonCreditRestrictions}
											onChange={(e) =>
												handleChangeFormData(
													e,
													'noPersonCreditRestrictions'
												)
											}
											displayEmpty
											className={classes.selectEmpty}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</Select>
									</FormControl>
								</Box>

								<Box mt={1}>
									<FormControl className={classes.formControl}>
										<InputLabel
											shrink
											id="should-receive-invoices-field-select"
										>
											Receive invoice duplicates
										</InputLabel>
										<Select
											fullWidth
											labelId="should-receive-invoices-select"
											value={formData.shouldReceiveInvoices}
											onChange={(e) =>
												handleChangeFormData(e, 'shouldReceiveInvoices')
											}
											displayEmpty
											className={classes.selectEmpty}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</Select>
									</FormControl>
								</Box>

								<Box mt={1}>
									<TextField
										fullWidth
										type={'text'}
										onChange={(e) =>
											handleChangeFormData(e, 'amountMismatchTolerance')
										}
										onFocus={setAmountMismatchToleranceValue}
										onBlur={setAmountMismatchToleranceValue}
										label="Amount mismatch tolerance"
										value={amountMismatchTolerance}
									/>
								</Box>

								<Box mt={4}>
									<Button type={'submit'} color="secondary" variant="contained">
										{'Save Merchant'}
									</Button>
								</Box>
							</Box>
						</Paper>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default MerchantAdd
