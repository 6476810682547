import { useTranslation } from 'react-i18next'

import formatDate from '../../../utils/formatDate'

import { API_InvoiceList } from '../../../config/constants'

import { formatTaxPrice, formatInvoiceType} from '../../../utils/formatCurrency'
import { SortableTable } from '../../../components/shared/SortableTable'

const InvoiceListTable = ({}) => {
	const { t } = useTranslation()

	const headCells = [
		{ id: 'id', label: 'No' },
		{ id: 'invoiceStatus', company: true, label: t('invoice.invoiceStatus') },
		{ id: 'invoiceType', company: true, label: t('invoice.invoiceType') , format: formatInvoiceType},
		{
			id: 'totalPriceTaxed',
			company: true,
			label: t('totalPriceTaxed'),
			format: formatTaxPrice
		},
		{ id: 'orgName', company: true, label: t('companyName') },
		{ id: 'merchantName', company: true, label: t('merchantName') },
		{ id: 'createdAt', label: t('date'), format: formatDate }
	]

	return (
		<>
			<SortableTable
				apiUrl={API_InvoiceList}
				detailUrl="/invoices/detail"
				headCells={headCells}
			/>
		</>
	)
}

export default InvoiceListTable
