import { useTranslation } from 'react-i18next'

import axios from '../../../../utils/axios'
import { API_URL, API_MerchantKeyList } from '../../../../config/constants'

import { TableCell, Button, Box } from '@material-ui/core'

import { SortableTable } from '../../../../components/shared/SortableTable'
import { useContext, useState } from 'react'
import { SnackbarContext } from '../../../../context'
import { useActiveTab } from '../../../../hooks'

const MerchantKeysListTable = ({ mer_id, active }) => {
	const loadData = useActiveTab(active)
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [refresh, setRefresh] = useState(false)

	const headCells = [
		{ id: 'no', label: 'No', sortable: false },
		{ id: 'keyValue', company: true, label: t('merchant.key.value') },
		{ id: 'expiryDate', label: t('merchant.key.expiryDate') },
		{ id: 'merchantId', label: t('merchant.key.merchantId') },
		{ id: 'keyVersion', label: t('merchant.key.version') }
	]

	const handleAddKey = () => {
		axios
			.post(`${API_URL}${API_MerchantKeyList}/${mer_id}`, {})
			.then(({ data }) => {
				setRefresh(true)
			})
			.catch((e) => {
				showMessage(t('errors.500'), 'error')
			})
	}

	const renderBodyCells = (result, i) => {
		return (
			<>
				<TableCell>{i + 1}</TableCell>
				<TableCell>{result.keyValue}</TableCell>
				<TableCell>{result.expiryDate}</TableCell>
				<TableCell>{result.merchantId}</TableCell>
				<TableCell>{result.keyVersion}</TableCell>
			</>
		)
	}

	return loadData ? (
		<>
			<Box mt={3}>
				<Button variant="outlined" color="primary" onClick={() => handleAddKey()}>
					Add Key
				</Button>
			</Box>

			<SortableTable
				apiUrl={`${API_MerchantKeyList}/${mer_id}`}
				headCells={headCells}
				order={'name'}
				renderBodyCells={renderBodyCells}
				refresh={refresh}
				setRefresh={setRefresh}
			/>
		</>
	) : (
		<></>
	)
}

export default MerchantKeysListTable
