import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './amplify.css'
import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Amplify } from 'aws-amplify'
import config from './aws-exports'

Amplify.configure({
	...config,
	aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOLS_ID,
	aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
	aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_WEB_CLIENT_ID
})

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
