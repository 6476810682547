import formatDate from '../../../utils/formatDate'
import { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { API_Download, API_InvoiceList, API_URL, API_URL_SERVICE } from '../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import { useTranslation } from 'react-i18next'
import { formatTaxPrice } from '../../../utils/formatCurrency'
import DetailTable from '../../../components/shared/DetailTable/DetailTable'
import DetailRow from '../../../components/shared/DetailTable/DetailRow'
import { SnackbarContext, UserContext } from '../../../context'
import { Button, FormControl, MenuItem, Select } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useActiveTab } from '../../../hooks'

const InvoiceInfos = ({ inv_id, active }) => {
	const { showMessage } = useContext(SnackbarContext)

	const { user, isAdmin } = useContext(UserContext)
	const { t } = useTranslation()

	const [detail, setDetail] = useState(null)
	const loadData = useActiveTab(active)
	const invoiceStatuses = ['overdue', 'paid', 'unpaid']

	useEffect(() => {
		if (!inv_id || !loadData) return
		;(async () => {
			try {
				let res = await axios.get(`${API_URL}${API_InvoiceList}/${inv_id}`)
				let detailData = res.data

				setDetail({
					...detailData,
					dueDate: detailData.dueDate ? new Date(detailData.dueDate) : null
				})
			} catch (error) {
				console.log('loading error', error)
			}
		})()
	}, [inv_id, loadData])

	const handleStatusChange = (e) => {
		const value = e.target.value
		if (value) {
			setDetail({ ...detail, invoiceStatus: e.target.value })
		}
	}

	const handleDateChange = (value) => {
		setDetail({ ...detail, dueDate: value })
	}

	const updateInvoice = async () => {
		let date = null
		if (detail.dueDate) {
			const d = new Date(detail.dueDate)

			const day = d.getDate()
			const month = d.getMonth() + 1
			date = `${d.getFullYear()}-${month < 10 ? '0' + month : month}-${
				day < 10 ? '0' + day : day
			}T00:00:00.000Z`
		}

		try {
			let res = await axios.put(`${API_URL}${API_InvoiceList}/${inv_id}`, {
				dueDate: date,
				invoiceStatus: detail.invoiceStatus,
				username: user.username
			})
			console.log(res)
			showMessage('Saved', 'success')
		} catch (error) {
			console.log('loading error', error)
			showMessage(t('errors.500'), 'error')
		}
	}

	return (
		<>
			{detail ? (
				<>
					<DetailTable title={'general.basicInformation'}>
						<DetailRow
							name={'invoice.detail.type'}
							value={
								detail.invoiceType === 'INV01' ? 'Basic invoice' : 'Refund invoice'
							}
						/>
						<DetailRow
							name={'invoice.detail.download'}
							value={
								detail.pdfFilePath !== null ? (
									<a
										href={`${API_URL_SERVICE}${API_Download}` + detail.uuid}
										target="_blank"
									>
										{t('download')}
									</a>
								) : (
									'Invoice not available'
								)
							}
						/>
						<DetailRow
							name={'date'}
							value={detail['createdAt'] !== '' ? formatDate(detail.createdAt) : ''}
						/>
						<DetailRow
							name={'companyName'}
							value={
								<a href={'../../organizations/detail/' + detail.orgId}>
									{t(detail['orgName'])}{' '}
								</a>
							}
						/>
						<DetailRow name={'invoice.buyerName'} value={detail.buyerName} />
						<DetailRow name={'invoice.buyerEmail'} value={detail.buyerEmail} />
						<DetailRow name={'invoice.buyerPhone'} value={detail.buyerPhone} />
						<DetailRow name={'invoice.email'} value={detail.email} />
					</DetailTable>

					<DetailTable title={'invoice.heading.paymentDetails'}>
						<DetailRow
							name={'invoice.amount'}
							value={
								formatTaxPrice(detail.amount) !== ''
									? formatTaxPrice(detail.amount)
									: ''
							}
						/>
						<DetailRow
							name={'invoice.amountPaid'}
							value={
								formatTaxPrice(detail['amountPaid']) !== ''
									? formatTaxPrice(detail.amountPaid)
									: ''
							}
						/>

						{isAdmin() ? (
							<>
								<DetailRow
									name={'invoice.invoiceStatus'}
									value={
										<FormControl fullWidth variant={'outlined'} size={'small'}>
											<Select
												id="statuis-simple-select"
												size={'sm'}
												value={
													invoiceStatuses.indexOf(
														detail['invoiceStatus']
													) === -1
														? ''
														: detail['invoiceStatus']
												}
												inputProps={{
													placeholder: detail['invoiceStatus']
												}}
												onChange={handleStatusChange}
												displayEmpty
											>
												<MenuItem disabled value="">
													<em>{detail['invoiceStatus']}</em>
												</MenuItem>
												{invoiceStatuses.map((status, i) => (
													<MenuItem key={`status-${i}`} value={status}>
														{status}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									}
								/>
								<DetailRow
									name={'invoice.dueDate'}
									value={
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												variant="inline"
												size={'small'}
												inputVariant="outlined"
												fullWidth={true}
												placeholder="dd/mm/yyyy"
												format="dd/MM/yyyy"
												label={'Due date'}
												value={detail.dueDate}
												onChange={handleDateChange}
											/>
										</MuiPickersUtilsProvider>
									}
								/>

								<DetailRow
									name={''}
									value={
										<Button
											onClick={updateInvoice}
											variant={'contained'}
											color={'primary'}
										>
											Update
										</Button>
									}
								/>
							</>
						) : (
							<>
								<DetailRow
									name={'invoice.invoiceStatus'}
									value={detail['invoiceStatus']}
								/>
								<DetailRow
									name={'invoice.dueDate'}
									value={formatDate(detail.dueDate, false)}
								/>
							</>
						)}
					</DetailTable>

					<DetailTable title={'invoice.heading.deliveryDetails'}>
						<DetailRow
							name={'invoice.invoicingMethod'}
							value={detail['requestedInvoicingMethod']}
						/>
						<DetailRow
							name={'invoice.uploadedToEfcom'}
							value={detail['uploadedToEfcom'] === '1' ? 'True' : 'False'}
						/>
						<DetailRow
							name={'invoice.uploadedToProfiform'}
							value={detail['uploadedToProfiforms'] === '1' ? 'True' : 'False'}
						/>
						<DetailRow
							name={'invoice.sentToBuyerAt'}
							value={
								detail['sentToBuyerAt'] === null ? 'Not sent' : detail.sentToBuyerAt
							}
						/>
						<DetailRow
							name={'invoice.sentToMerchantAt'}
							value={
								detail['sentToMerchantAt'] === null
									? 'Not sent'
									: detail.sentToMerchantAt
							}
						/>
					</DetailTable>

					<DetailTable title={'invoice.heading.TransactionDetails'}>
						<DetailRow
							name={'invoices.purchaseId'}
							value={
								<a href={'../../purchases/detail/' + detail['purchaseId']}>
									{t(detail['purchaseId'])}{' '}
								</a>
							}
						/>
						<DetailRow
							name={'invoices.originalTransactionId'}
							value={
								<a
									href={
										'../../transactions/detail/' +
										detail['originalTransactionId']
									}
								>
									{t(detail['originalTransactionId'])}{' '}
								</a>
							}
						/>
						<DetailRow
							name={'invoices.transactionId'}
							value={
								<a href={'../../transactions/detail/' + detail['transactionId']}>
									{t(detail['transactionId'])}{' '}
								</a>
							}
						/>
					</DetailTable>
				</>
			) : (
				<></>
			)}
		</>
	)
}

export default InvoiceInfos
