import { Button, FormControl, MenuItem, Select, Box } from '@material-ui/core'
import { useEffect, useContext, useState } from 'react'
import axios from '../../../utils/axios'
import { API_URL, API_UserList } from '../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import { useTranslation } from 'react-i18next'
import DetailRow from '../../../components/shared/DetailTable/DetailRow'
import DetailTable from '../../../components/shared/DetailTable/DetailTable'
import { SnackbarContext, UserContext } from '../../../context'
import useStyles from './UserDetail.styles'

const UserInfos = (props) => {
	const { user_id } = props
	const classes = useStyles()
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)
	const { user } = useContext(UserContext)

	const [detail, setDetail] = useState({
		id: '',
		name: '',
		primaryEmail: '',
		phoneNumber: '',
		allowMultipleOrgs: '',
		isDeactivated: '',
		createdAt: ''
	})

	useEffect(() => {
		;(async () => {
			try {
				let res = await axios.get(`${API_URL}${API_UserList}/${user_id}`)
				console.log(res)
				let detailData = res.data
				setDetail(camelcaseKeys(detailData, { deep: true }))
			} catch (error) {
				console.log('loading error', error)
			}
		})()
	}, [user_id])

	const updateUser = async () => {
		try {
			await axios.put(`${API_URL}${API_UserList}/${detail.id}`, {
				isDeactivated: detail.isDeactivated,
				allowMultipleOrgs: detail.allowMultipleOrgs,
				username: user.username
			})

			showMessage('Saved', 'success')
		} catch (e) {
			console.log('failed', e)
			showMessage(t('errors.500'), 'error')
		}
	}

	const handleInputChange = (e, prop) => {
		setDetail({ ...detail, [prop]: e.target.value })
	}

	return (
		<>
			<Box mt={2} mb={4}>
				<DetailTable title={'general.basicInformation'}>
					<DetailRow name={'user.name'} value={detail['name']} />
					<DetailRow name={'user.primaryEmail'} value={detail['primaryEmail']} />
					<DetailRow name={'user.phoneNumber'} value={detail['phoneNumber']} />
					<DetailRow name={'user.dob'} value={detail['dob']} />
					<DetailRow
						name={'user.isEmailVerified'}
						value={detail['isEmailVerified'] == true ? 'Yes' : 'No'}
					/>
					<DetailRow
						name={'user.isPhoneNumberVerified'}
						value={detail['isPhoneNumberVerified'] == true ? 'Yes' : 'No'}
					/>
					<DetailRow
						name={'user.detail.allowMultipleOrgs'}
						value={
							<FormControl fullWidth variant={'outlined'} size={'small'}>
								<Select
									id="statuis-simple-select"
									size={'sm'}
									value={
										detail.allowMultipleOrgs == false &&
										detail.allowMultipleOrgs !== ''
											? false
											: true
									}
									onChange={(e) => handleInputChange(e, 'allowMultipleOrgs')}
									displayEmpty
								>
									<MenuItem value={true}>True</MenuItem>
									<MenuItem value={false}>False</MenuItem>
								</Select>
							</FormControl>
						}
					/>

					<DetailRow
						name={'user.detail.isDeactivated'}
						value={
							<FormControl fullWidth variant={'outlined'} size={'small'}>
								<Select
									id="statuis-simple-select"
									size={'sm'}
									value={
										detail.isDeactivated == false && detail.isDeactivated !== ''
											? false
											: true
									}
									onChange={(e) => handleInputChange(e, 'isDeactivated')}
									displayEmpty
								>
									<MenuItem value={true}>Dectivated</MenuItem>
									<MenuItem value={false}>Activated</MenuItem>
								</Select>
							</FormControl>
						}
					/>
				</DetailTable>
				<Button color="primary" variant="contained" onClick={updateUser}>
					{t('user.updateUser')}
				</Button>
			</Box>
		</>
	)
}

export default UserInfos
