import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 260,
		flex: 1
	}
}))

export default useStyles
