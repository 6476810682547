import { useTranslation } from 'react-i18next'
import { useActiveTab } from '../../../../hooks'
import { SortableTable } from '../../../../components/shared/SortableTable'
import { API_PurchaseList, API_ReminderList } from '../../../../config/constants'
import { formatTaxPrice } from '../../../../utils/formatCurrency'

const PurchaseRemindersList = ({ pur_id, active }) => {
	const { t } = useTranslation()
	const loadData = useActiveTab(active)

	const headCells = [
		{ id: 'reminderId', label: 'ID' },
		{ id: 'invoiceId', label: t('reminders.invoiceId') },
		{ id: 'purchaseId', label: t('general.purchaseId') },
		{ id: 'amount', label: t('reminders.amount'), format: formatTaxPrice },
		{ id: 'additionalFee', label: t('reminders.additionalFee'), format: formatTaxPrice }
	]

	return loadData ? (
		<SortableTable
			apiUrl={`${API_PurchaseList}/${pur_id}${API_ReminderList}`}
			detailUrl="/reminders/detail"
			headCells={headCells}
			order={'id'}
		/>
	) : (
		<></>
	)
}

export default PurchaseRemindersList
