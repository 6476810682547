import { Box, Tooltip } from '@material-ui/core'
import { InfoOutlined as Info } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const TableCellWithTooltip = ({ title, tooltip }) => {
	const { t, i18n } = useTranslation()

	const classes = useStyles()

	return (
		<Box display={'flex'} alignItems={'center'} gridGap={12}>
			<span> {t(title)}</span>
			{tooltip && i18n.exists(tooltip) && (
				<Tooltip
					title={t(tooltip)}
					placement={'bottom-start'}
					interactive
					classes={{
						tooltip: classes.tooltip
					}}
				>
					<Box paddingX={1} className={classes.box}>
						<Info
							classes={{
								root: classes.iconRoot
							}}
						/>
					</Box>
				</Tooltip>
			)}
		</Box>
	)
}

export default TableCellWithTooltip
