import { useEffect, useState } from 'react'

const useActiveTab = (active) => {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		if (active && !loaded) {
			setLoaded(true)
		}
	}, [active])

	return loaded
}

export default useActiveTab
