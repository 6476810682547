import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box, Breadcrumbs, Typography, Tab, Tabs } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import PurchaseInfo from './PurchaseInfo'
import { useTranslation } from 'react-i18next'
import PurchaseHistoryListTable from './PurchaseHistoryList/PurchaseHistoryListTable'
import PurchaseItemsListTable from './PurchaseItemsList/PurchaseItemsListTable'
import PurchaseTransactionsList from './PurchaseTransactionsList'
import PurchaseInvoicesList from './PurchaseInvoicesList'
import PurchaseRemindersList from './PurchaseRemindersList'
import useStyles from './PurchaseDetail.styles'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box>{children}</Box>
		</Typography>
	)
}

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	}
}

const PurchaseDetail = () => {
	const { t } = useTranslation()

	const [value, setValue] = useState(0)

	function handleChange(event, newValue) {
		setValue(newValue)
	}

	const params = useParams()
	const classes = useStyles()

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('purchase.detail')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					{t('dashboard')}
				</Link>
				<Link
					className={classes.link}
					color="inherit"
					variant={'subtitle2'}
					to="/purchases"
				>
					{t('purchases')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('detail')}
				</Typography>
			</Breadcrumbs>

			<Tabs
				className={classes.tabs}
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				aria-label="scrollable auto tabs example"
			>
				<Tab
					className={classes.tab}
					label={t('purchase.detail.information')}
					{...a11yProps(0)}
				/>
				<Tab
					className={classes.tab}
					label={t('purchase.detail.histories')}
					{...a11yProps(1)}
				/>
				<Tab className={classes.tab} label={t('purchase.detail.items')} {...a11yProps(2)} />
				<Tab
					className={classes.tab}
					label={t('purchase.detail.transactions')}
					{...a11yProps(3)}
				/>
				<Tab
					className={classes.tab}
					label={t('purchase.detail.invoices')}
					{...a11yProps(4)}
				/>
				<Tab
					className={classes.tab}
					label={t('purchase.detail.reminders')}
					{...a11yProps(5)}
				/>
			</Tabs>

			<TabPanel value={value} index={0}>
				<PurchaseInfo pur_id={params.id} active={value === 0} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<PurchaseHistoryListTable pur_id={params.id} active={value === 1} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<PurchaseItemsListTable pur_id={params.id} active={value === 2} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<PurchaseTransactionsList pur_id={params.id} active={value === 3} />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<PurchaseInvoicesList pur_id={params.id} active={value === 4} />
			</TabPanel>
			<TabPanel value={value} index={5}>
				<PurchaseRemindersList pur_id={params.id} active={value === 5} />
			</TabPanel>
		</>
	)
}

export default PurchaseDetail
