import { useTranslation } from 'react-i18next'
import { useActiveTab } from '../../../../hooks'
import formatDate from '../../../../utils/formatDate'
import { SortableTable } from '../../../../components/shared/SortableTable'
import { API_PurchaseList, API_InvoiceList } from '../../../../config/constants'
import {formatInvoiceType, formatTaxPrice} from "../../../../utils/formatCurrency";

const PurchaseInvoicesList = ({ pur_id, active }) => {
	const { t } = useTranslation()
	const loadData = useActiveTab(active)

	const headCells = [
		{ id: 'id', label: 'ID' },
		{ id: 'purchaseId', label: t('general.purchaseId') },
		{ id: 'transactionId', label: t('general.transactionId') },
		{ id: 'invoiceStatus', label: t('invoice.invoiceStatus') },
		{ id: 'invoiceType', label: t('invoice.invoiceType'), format: formatInvoiceType},
		{ id: 'invoicingDate', label: 'Invoice Date', format: formatDate },
		{ id: 'orgName', label: t('transaction.orgName') },
		{ id: 'merchantName', label: t('transaction.merchantName') },
		{ id: 'createdAt', label: t('transaction.date'), format: formatDate },
		{ id: 'totalPriceTaxed', label: t('transaction.totalPriceTaxed'), format: formatTaxPrice}
	]

	return loadData ? (
		<SortableTable
			apiUrl={`${API_PurchaseList}/${pur_id}${API_InvoiceList}`}
			headCells={headCells}
			detailUrl="/invoices/detail"
			order={'id'}
		/>
	) : (
		<></>
	)
}

export default PurchaseInvoicesList
