import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Box, Breadcrumbs, Typography, Tab, Tabs } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import MerchantInfos from './MerchantInfos'
import EcomMerchantList from './EcomMerchantList'
import { useTranslation } from 'react-i18next'
import MerchantKeysListTable from './MerchantKeysList/MerchantKeysListTable'

import useStyles from './MerchantDetail.styles'

function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box>{children}</Box>
		</Typography>
	)
}

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	}
}

const MerchantDetail = () => {
	const [value, setValue] = useState(0)
	const { t } = useTranslation()
	function handleChange(event, newValue) {
		setValue(newValue)
	}

	const params = useParams()

	const classes = useStyles()

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('details')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					{t('dashboard')}
				</Link>
				<Link
					className={classes.link}
					color="inherit"
					variant={'subtitle2'}
					to="/merchants"
				>
					{t('merchant_list')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('details')}
				</Typography>
			</Breadcrumbs>

			<Tabs
				className={classes.tabs}
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				aria-label="scrollable auto tabs example"
			>
				<Tab className={classes.tab} label={t('details')} {...a11yProps(0)} />
				<Tab className={classes.tab} label={t('merchant.keys')} {...a11yProps(1)} />
				<Tab
					className={classes.tab}
					label={t('merchant.efcom_credentials')}
					{...a11yProps(2)}
				/>
			</Tabs>

			<TabPanel value={value} index={0}>
				<MerchantInfos mer_id={params.id} active={value === 0} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<MerchantKeysListTable mer_id={params.id} active={value === 1} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<EcomMerchantList mer_id={params.id} active={value === 2} />
			</TabPanel>
		</>
	)
}

export default MerchantDetail
