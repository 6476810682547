import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import axios from '../../../../../utils/axios'
import { API_URL } from '../../../../../config/constants'
import { Box, Button, Dialog, DialogContent, TextField } from '@material-ui/core'

const OrganizationWhitelistEmailForm = ({ orgId, closeModal }) => {
	const { t } = useTranslation()

	const [input, setInput] = useState('')

	const [openModal, setOpenModal] = useState(false)

	const handleOpen = () => {
		setOpenModal(true)
	}

	const handleClose = () => {
		setOpenModal(false)
	}

	const onSubmit = async (e) => {
		e.preventDefault()

		await axios.post(`${API_URL}/whitelist/email`, {
			email: input,
			orgId: orgId
		})

		handleClose()
	}

	const handleChangeFormData = (e) => {
		setInput(e.target.value)
	}

	return (
		<>
			<Dialog open={openModal} onClose={handleClose}>
				<DialogContent>
					<Box my={2}>
						<form onSubmit={onSubmit}>
							<Box mb={4}>
								<TextField
									fullWidth
									onChange={handleChangeFormData}
									label="Email"
									value={input}
								/>
							</Box>
							<Button type={'submit'} variant={'contained'}>
								Submit
							</Button>
						</form>
					</Box>
				</DialogContent>
			</Dialog>

			<Button variant={'contained'} color={'primary'} onClick={handleOpen}>
				{t('organization.whitelist.addEmail')}
			</Button>
		</>
	)
}

export default OrganizationWhitelistEmailForm
