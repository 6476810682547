import { useTranslation } from 'react-i18next'
import { API_UserList } from '../../../config/constants'

import { SortableTable } from '../../../components/shared/SortableTable'

const UserListTable = ({}) => {
	const { t } = useTranslation()

	const headCells = [
		{ id: 'name', company: true, label: t('user.name') },
		{ id: 'primaryEmail', label: t('user.primaryEmail') },
		{ id: 'phoneNumber', label: t('user.phoneNumber') },
		{ id: 'createdAt', label: t('user.createdAt') }
	]
	/*
	const gotoResultDetail = (id) => {
		navigate(`/organizations/detail/${id}`)
	}

	const renderBodyCells = (result) => {
		return (
			<>
				<TableCell onClick={() => gotoResultDetail(result.id)}>{result.name}</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>
					{result.businessId}
				</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>{result.vatId}</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>
					{result.tebaClientId === null ? '' : result.tebaClientId}
				</TableCell>
			</>
		)
	}
	*/

	return (
		<SortableTable
			apiUrl={API_UserList}
			detailUrl="/users/detail"
			headCells={headCells}
			//renderBodyCells={renderBodyCells}
		/>
	)
}

export default UserListTable
