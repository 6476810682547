import { Box } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { API_TransactionList, API_URL } from '../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import { SnackbarContext } from '../../../context'
import { useTranslation } from 'react-i18next'
import DetailTable from '../../../components/shared/DetailTable/DetailTable'
import DetailRow from '../../../components/shared/DetailTable/DetailRow'
import { useActiveTab } from '../../../hooks'

const TransactionInfos = ({ tran_id, active }) => {
	const loadData = useActiveTab(active)
	const { t } = useTranslation()

	const { showMessage } = useContext(SnackbarContext)

	const [detail, setDetail] = useState({
		id: '',
		name: '',
		merchantId: '',
		transactionType: '',
		purchaseStatus: '',
		purchaseId: '',
		request: '',
		createdAt: ''
	})

	useEffect(() => {
		if (!tran_id || !loadData) return
		;(async () => {
			try {
				let res = await axios.get(`${API_URL}${API_TransactionList}/${tran_id}`)
				let detailData = res.data
				setDetail(camelcaseKeys(detailData, { deep: true }))
			} catch (error) {
				console.log('loading error', error)
				showMessage(t('errors.500'), 'error')
			}
		})()
	}, [tran_id, loadData])

	return (
		<>
			<Box mt={2} mb={4}>
				<DetailTable title={'general.basicInformation'}>
					<DetailRow name={t('transaction.detail.name')} value={detail.name} />
					<DetailRow
						name={t('transaction.detail.merchantId')}
						value={detail.merchantId}
					/>
					<DetailRow
						name={t('transaction.detail.transactionType')}
						value={detail.transactionType}
					/>
					<DetailRow
						name={t('invoices.purchaseId')}
						value={
							<a href={'../../purchases/detail/' + detail['purchaseId']}>
								{t(detail['purchaseId'])}{' '}
							</a>
						}
					/>
					<DetailRow
						name={t('transaction.detail.purchaseStatus')}
						value={detail.purchaseStatus}
					/>
					<DetailRow
						name={t('transaction.detail.request')}
						value={<div style={{ overflowWrap: 'anywhere' }}>{detail.request}</div>}
					/>
					<DetailRow name={t('general.creationDate')} value={detail.createdAt} />
				</DetailTable>
			</Box>
		</>
	)
}

export default TransactionInfos
