import { useTranslation } from 'react-i18next'

import formatDate from '../../../utils/formatDate'
import { API_ProbabilityOfDefaults } from '../../../config/constants'

import { formatTaxPrice,formatPDValue } from '../../../utils/formatCurrency'
import { SortableReportTable } from '../../../components/shared/SortableTable'


const AverageProbabilityOfDefaultsTable = ({}) => {

    const { t } = useTranslation()

    const headCells = [
        { id: 'openInvoiceAmount', label: t('report.unpaid_invoice_amount'), format: formatTaxPrice },
		{ id: 'average', label: t('report.average'), format: formatPDValue },
        { id: 'weightedAverage', label: t('report.weighted_average'), format: formatPDValue }
    ]

        return (
            
            <SortableReportTable
                apiUrl={API_ProbabilityOfDefaults + "/average"}
                headCells={headCells}
                order="openInvoiceAmount"
            />
        )
    }
    
    export default AverageProbabilityOfDefaultsTable
    