import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { Box, Typography, Breadcrumbs, Button } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import OrganizationListTable from './OrganizationListTable'
import useStyles from './OrganizationList.styles'

const OrganizationList = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const classes = useStyles()

	const handleAddOrganizationClick = () => {
		navigate('/organizations/add')
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('organization_list')}
				</Typography>

				<Box>
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddOrganizationClick}
						className={classes.companyAddButton}
					>
						{t('organization.add')}
					</Button>
				</Box>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					{t('dashboard')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('organization_list')}
				</Typography>
			</Breadcrumbs>

			<OrganizationListTable />
		</>
	)
}

export default OrganizationList
