import { useTranslation } from 'react-i18next'

import { API_OrganizationList, API_History } from '../../../../config/constants'

import formatDate from '../../../../utils/formatDate'
import { SortableTable } from '../../../../components/shared/SortableTable'
import { useActiveTab } from '../../../../hooks'

const OrganizationHistoryListTable = ({ org_id, active }) => {
	const { t } = useTranslation()

	const loadData = useActiveTab(active)

	const headCells = [
		{ id: 'newValues', label: t('history.newValues') },
		{ id: 'userId', label: t('history.userId') },
		{ id: 'timestamp', label: t('history.timestamp'), format: formatDate }
	]

	return loadData ? (
		<SortableTable
			apiUrl={`${API_OrganizationList}/${org_id}${API_History}`}
			headCells={headCells}
			order={'timestamp'}
		/>
	) : (
		<></>
	)
}

export default OrganizationHistoryListTable
