import { useTranslation } from 'react-i18next'

import formatDate from '../../../utils/formatDate'
import { API_ProbabilityOfDefaults } from '../../../config/constants'

import { formatEurocentsToEuro,formatPDValue } from '../../../utils/formatCurrency'
import { SortableReportTable } from '../../../components/shared/SortableTable'


const MerchantProbabilityOfDefaultsTable = ({}) => {

    const { t } = useTranslation()

    const headCells = [
        { id: 'merchantName', label: t('report.merchant_name') },
        { id: 'openInvoiceAmount', label: t('report.unpaid_invoice_amount'), format: formatEurocentsToEuro },
		{ id: 'average', label: t('report.average'), format: formatPDValue },
        { id: 'weightedAverage', label: t('report.weighted_average'), format: formatPDValue }
    ]

        return (
            <SortableReportTable
                apiUrl={API_ProbabilityOfDefaults + "/merchants"}
                headCells={headCells}
                order="merchant_name"
            />
        )
    }
    
    export default MerchantProbabilityOfDefaultsTable
    