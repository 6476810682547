import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	tooltip: {
		fontSize: 14,
		padding: theme.spacing(2),
		whiteSpace: 'pre-line'
	},
	iconRoot: {
		opacity: '0.4'
	},
	box: {
		height: 24
	}
}))

export default useStyles
