import { useTranslation } from 'react-i18next'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router'

import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'

import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	LinearProgress,
	TableSortLabel,
	Button,
	Grid
} from '@material-ui/core'

import { PAGE_SIZE, ORDER_DIRS } from '../../../config/constants'
import { SnackbarContext } from '../../../context'

import useStyles from './SortableTable.styles'

const SortableReportTable = ({
	apiUrl,
	detailUrl,
	headCells,
	renderBodyCells,
	order,
	refresh,
	setRefresh
}) => {
	const { showMessage } = useContext(SnackbarContext)
	const navigate = useNavigate()
	const { t } = useTranslation()

	const classes = useStyles()

	const [items, setItems] = useState([])
	const [orderBy, setOrderBy] = useState(order || 'createdAt')
	const [orderDir, setOrderDir] = useState('desc')
	const [keyword, setKeyword] = useState('')
	const [offset, setOffset] = useState(0)
	const [loadFinished, setLoadFinish] = useState(false)

	const [triggerSearch, setTriggerSearch] = useState(true)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (refresh) {
			setRefresh(false)
			resetSearch()
		}
	}, [refresh])

	useEffect(() => {
		if (triggerSearch) {
			loadMore()
			setTriggerSearch(false)
		}
	}, [triggerSearch])

	const loadMore = () => {
		setLoading(true)

		const params = snakecaseKeys(
			{
				limit: PAGE_SIZE,
				offset,
				orderBy,
				orderDir: orderDir === 'asc' ? ORDER_DIRS.ASC : ORDER_DIRS.DESC,
				q: keyword
			},
			{ deep: true }
		)

		getData(params)
	}

	const getData = (params) => {
		axios
			.get(`${API_URL}${apiUrl}`, { params })
			.then(({ data }) => {
				console.log(data)
				data = camelcaseKeys(data, { deep: true })
				let temp = items
				temp = temp.concat(data)
				console.log('temp', temp)

				setItems(temp)

				if (data.length < PAGE_SIZE) setLoadFinish(true)

				setOffset(offset + 1)
			})
			.catch((e) => {
				showMessage(t('errors.500'), 'error')
				setLoadFinish(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const resetSearch = () => {
		setOffset(0)
		setItems([])
		setLoadFinish(false)

		setTriggerSearch(true)
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0].value
		if (value !== keyword) {
			setKeyword(value)
			resetSearch()
		}
	}

	const handleSortChange = (newOrderBy) => {
		if (newOrderBy === orderBy) {
			setOrderDir(orderDir === 'desc' ? 'asc' : 'desc')
		} else {
			setOrderDir('asc')
		}

		setOrderBy(newOrderBy)

		resetSearch()
	}

	const gotoResultDetail = (id) => {
		if (detailUrl) {
			navigate(`${detailUrl}/${id}`)
		}
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="Companies Table">
					<TableHead>
						<TableRow>
							{/*<TableCell width={'10%'}>No</TableCell>*/}
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									sortDirection={orderBy === headCell.id ? orderDir : false}
									width={`${100 / headCells.length}%`}
								>
									{headCell.sortable === false ? (
										headCell.label
									) : (
										<TableSortLabel
											active={orderBy === headCell.id}
											direction={orderBy === headCell.id ? orderDir : 'asc'}
											onClick={() => {
												handleSortChange(headCell.id, headCell.company)
											}}
										>
											{headCell.label}
										</TableSortLabel>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((result, i) => (
							<TableRow className={classes.tableRow} key={`result-table-row-${i}`}>
								{/*<TableCell>{result.id || i + 1}</TableCell>*/}
								{renderBodyCells
									? renderBodyCells(result, i)
									: headCells.map((headCell) => (
											<TableCell
												onClick={() =>
													gotoResultDetail(result.id || result.reminderId)
												}
												key={headCell.id}
											>
												{headCell.format
													? headCell.format(result[headCell.id])
													: result[headCell.id]}
											</TableCell>
										))}
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						{!loadFinished && (
							<TableRow>
								<TableCell colSpan={headCells.length + 1}>
									<Grid
										container
										justifyContent="center"
										direction="column"
										alignItems="center"
									>
										{loading && <LinearProgress className={classes.progress} />}
										<Button
											disabled={loading}
											variant="outlined"
											color="primary"
											onClick={loadMore}
										>
											{t('load_more')}
										</Button>
									</Grid>
								</TableCell>
							</TableRow>
						)}
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	)
}

export default SortableReportTable
