const API_URL = process.env.REACT_APP_API_URL
const API_URL_SERVICE = process.env.REACT_APP_API_URL_SERVICE

const API_MerchatList = `/merchants`
const API_ReminderList = `/reminders`
const API_InvoiceList = `/invoices`
const API_MerchantKeyList = `/merchant_keys`
const API_EcomMerchantList = `/efcom-merchant-infos`
const API_OrganizationList = `/organisations`
const API_UserList = `/users`
const API_TransactionList = `/transactions`
const API_TransactionListExport = `${API_TransactionList}/export/xlsx`
const API_Items = `/items`
const API_History = `/history`
const API_PurchaseList = `/purchases`
const API_Download = `/api/download/download-pdf-invoice/`
const API_DownloadReminder = `/api/download/download-pdf-reminder/`

const API_MonthlyVolumeReport = '/monthly_volume_report'
const API_MonthlyVolumeReportExport = `${API_MonthlyVolumeReport}/export/xlsx`

const API_TransactionActivityReport = '/transaction_activity_report'
const API_TransactionActivityReportExport = `${API_TransactionActivityReport}/export/xlsx`

const API_MonthlyInvoiceVolumeReport = '/monthly_invoice_volume_report'
const API_MonthlyInvoiceVolumeReportExport = `${API_MonthlyInvoiceVolumeReport}/export/xlsx`


const API_UncapturedPurchases = '/uncaptured_purchases'
const API_UncapturedPurchasesExport = `${API_UncapturedPurchases}/export/xlsx`

const API_ProbabilityOfDefaults = '/probability_of_defaults'
const API_DebtorsPDReportExport = '/probability_of_defaults/debtors/export/xlsx'

const API_DunningsReport = '/dunnings_report'
const API_DunningsReportExport = '/dunnings_report/export/xlsx'

const LANGUAGES = {
	en: 'English',
	fi: 'Finnish'
}
const LOCALES = {
	en: 'enUS',
	fi: 'fiFI'
}

const DEFAULT_LANGUAGE = Object.keys(LANGUAGES)[0]

const ORDER_DIRS = {
	ASC: 'Ascending',
	DESC: 'Descending'
}

const PAGE_SIZE = 20

export {
	API_URL,
	LANGUAGES,
	DEFAULT_LANGUAGE,
	LOCALES,
	ORDER_DIRS,
	PAGE_SIZE,
	API_MerchatList,
	API_ReminderList,
	API_MerchantKeyList,
	API_EcomMerchantList,
	API_OrganizationList,
	API_UserList,
	API_TransactionList,
	API_TransactionListExport,
	API_Items,
	API_PurchaseList,
	API_History,
	API_InvoiceList,
	API_Download,
	API_URL_SERVICE,
	API_DownloadReminder,
	API_MonthlyVolumeReport,
	API_MonthlyVolumeReportExport,
	API_MonthlyInvoiceVolumeReport,
	API_MonthlyInvoiceVolumeReportExport,
	API_UncapturedPurchases,
	API_UncapturedPurchasesExport,
	API_TransactionActivityReport,
	API_TransactionActivityReportExport,
	API_ProbabilityOfDefaults,
	API_DebtorsPDReportExport,
	API_DunningsReport,
	API_DunningsReportExport	

}
