import { useTranslation } from 'react-i18next'

import { API_PurchaseList, API_Items } from '../../../../config/constants'
import { formatTaxPrice } from '../../../../utils/formatCurrency'

import { TableCell } from '@material-ui/core'

import { SortableTable } from '../../../../components/shared/SortableTable'
import { useActiveTab } from '../../../../hooks'

const PurchaseItemsListTable = ({ pur_id, active }) => {
	const loadData = useActiveTab(active)

	const { t } = useTranslation()

	const headCells = [
		{ id: 'no', label: 'No', sortable: false },
		{ id: 'name', label: t('item.name'), width: 25 },
		{ id: 'quantity', label: t('item.quantity'), width: 10 },
		{ id: 'currency', label: t('item.currency'), width: 10 },
		{ id: 'unitPriceTaxFree', label: t('item.unitPriceTaxFree'), width: 10 },
		{ id: 'unitPriceTaxed', label: t('item.unitPriceTaxed'), width: 10 },
		{ id: 'originalPriceTaxed', label: t('item.originalPriceTaxed'), width: 10 },
		{ id: 'totalPriceTaxFree', label: t('item.totalPriceTaxFree'), width: 10 },
		{ id: 'totalPriceTaxed', label: t('item.totalPriceTaxed'), width: 10 }
	]

	const renderBodyCells = (result, i) => {
		return (
			<>
				<TableCell>{i + 1}</TableCell>
				<TableCell>{result.name}</TableCell>
				<TableCell>{result.quantity}</TableCell>
				<TableCell>{result.currency}</TableCell>
				<TableCell>{formatTaxPrice(result.unitPriceTaxFree)}</TableCell>
				<TableCell>{formatTaxPrice(result.unitPriceTaxed)}</TableCell>
				<TableCell>{formatTaxPrice(result.originalPriceTaxed)}</TableCell>
				<TableCell>{formatTaxPrice(result.totalPriceTaxFree)}</TableCell>
				<TableCell>{formatTaxPrice(result.totalPriceTaxed)}</TableCell>
			</>
		)
	}

	return loadData ? (
		<SortableTable
			apiUrl={`${API_PurchaseList}/${pur_id}${API_Items}`}
			headCells={headCells}
			order={'name'}
			renderBodyCells={renderBodyCells}
		/>
	) : (
		<></>
	)
}

export default PurchaseItemsListTable
