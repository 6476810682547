import { Button, FormControl, MenuItem, Select, TextField } from '@material-ui/core'
import formatDate from '../../../utils/formatDate'
import React, { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { API_OrganizationList, API_URL } from '../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import DetailTable from '../../../components/shared/DetailTable/DetailTable'
import { DetailRow } from '../../../components/shared/DetailTable'
import { SnackbarContext, UserContext } from '../../../context'
import { useTranslation } from 'react-i18next'
import { useActiveTab } from '../../../hooks'
import { formatEurocentsToEuro } from '../../../utils/formatCurrency'

const OrganizationInfos = ({ data, org_id, active }) => {
	const { showMessage } = useContext(SnackbarContext)
	const { t } = useTranslation()

	const { user } = useContext(UserContext)

	const [detail, setDetail] = useState(data)

	useEffect(() => {
		setDetail(data)
	}, [data])

	const handleInputChange = (e, prop) => {
		setDetail({ ...detail, [prop]: e.target.value })
	}

	const updateOrganization = async () => {
		try {
			await axios.put(`${API_URL}${API_OrganizationList}/${detail.id}`, {
				//creditLimit: detail.creditLimit,
				deactivated: detail.deactivated,
				username: user.username
			})

			showMessage('Saved', 'success')
		} catch (e) {
			console.log('failed', e)
			showMessage(t('errors.500'), 'error')
		}
	}

	return (
		<>
			<DetailTable title={'general.countryInformation'}>
				<DetailRow name={'general.country'} value={detail.country} />
				<DetailRow name={'general.currency'} value={detail.currency} />
				<DetailRow name={'general.contactLanguage'} value={detail.contactLanguage} />
			</DetailTable>

			<DetailTable title={'general.basicInformation'}>
				<DetailRow
					name={'general.creationDate'}
					value={detail.createdAt !== '' ? formatDate(detail.createdAt) : ''}
				/>
				<DetailRow
					name={'organization.detail.isWhitelisted'}
					value={detail.isWhitelisted ? 'Yes' : 'No'}
				/>
				<DetailRow name={'organization.name'} value={detail.name} />
				<DetailRow name={'organization.detail.businessId'} value={detail.businessId} />
				<DetailRow name={'organization.detail.vatId'} value={detail.vatId} />
				<DetailRow
					name={'organization.detail.deactivated'}
					value={
						<FormControl fullWidth variant={'outlined'} size={'small'}>
							<Select
								id="statuis-simple-select"
								size={'sm'}
								value={
									detail.deactivated == false && detail.deactivated !== ''
										? false
										: true
								}
								onChange={(e) => handleInputChange(e, 'deactivated')}
								displayEmpty
							>
								<MenuItem value={true}>Dectivated</MenuItem>
								<MenuItem value={false}>Activated</MenuItem>
							</Select>
						</FormControl>
					}
				/>
				<DetailRow name={'organization.detail.email'} value={detail.email} />
				<DetailRow name={'organization.detail.mobile'} value={detail.mobile} />
				<DetailRow name={'organization.detail.website'} value={detail.website} />
				<DetailRow name={'organization.detail.regOffice'} value={detail.regOffice} />
				<DetailRow
					name={'organization.detail.registrationNumber'}
					value={detail.registrationNumber}
				/>
				<DetailRow
					name={'organization.detail.creditLimit'}
					value={
						formatEurocentsToEuro(detail.remainingCreditLimit) +
						' / ' +
						formatEurocentsToEuro(detail.creditLimit)
					}
				/>
			</DetailTable>

			<Button color="primary" variant="contained" onClick={updateOrganization}>
				{t('organization.updateOrganization')}
			</Button>
		</>
	)
}

export default OrganizationInfos
