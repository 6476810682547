import { useTranslation } from 'react-i18next'

import { Box } from '@material-ui/core'

import MerchantProbabilityOfDefaultsTable from './MerchantProbabilityOfDefaultsTable'
import AverageProbabilityOfDefaultsTable from './AverageProbabilityOfDefaultsTable'
import DebtorsProbabilityOfDefaultReport from './DebtorsProbabilityOfDefaultReport'
import useStyles from './ProbabilityOfDefaultsReport.styles'

import { useState } from 'react'

const ProbabilityOfDefaultsReport = ({ active }) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const [loading, setLoading] = useState(false)

	return (
		<>
			{active && (
				<>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
					></Box>
					<DebtorsProbabilityOfDefaultReport />
					<AverageProbabilityOfDefaultsTable />
					<br></br>
					<MerchantProbabilityOfDefaultsTable />
				</>
			)}
		</>
	)
}

export default ProbabilityOfDefaultsReport
