import { API_MonthlyVolumeReport, API_MonthlyVolumeReportExport } from '../../../config/constants'
import { SortableTableDownload } from '../../../components/shared/SortableTable'

const MonthlyReportTable = () => {
	return (
		<SortableTableDownload
			apiUrl={API_MonthlyVolumeReport}
			downloadUrl={API_MonthlyVolumeReportExport}
		/>
	)
}

export default MonthlyReportTable
