import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Box, Typography, Breadcrumbs } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import ReminderListTable from './ReminderListTable'
import useStyles from './ReminderList.styles'

const ReminderList = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('reminders_list')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					{t('dashboard')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('reminders_list')}
				</Typography>
			</Breadcrumbs>

			<ReminderListTable />
		</>
	)
}

export default ReminderList
