import {
	API_TransactionActivityReport,
	API_TransactionActivityReportExport
} from '../../../config/constants'
import { SortableTableDownload } from '../../../components/shared/SortableTable'

const TransactionActivityReportTable = () => {
	return (
		<SortableTableDownload
			apiUrl={API_TransactionActivityReport}
			downloadUrl={API_TransactionActivityReportExport}
		/>
	)
}

export default TransactionActivityReportTable
