import { useTranslation } from 'react-i18next'

import { Box, Button } from '@material-ui/core'

import { API_DebtorsPDReportExport, API_URL } from '../../../config/constants'

import useStyles from './ProbabilityOfDefaultsReport.styles'
import axios from '../../../utils/axios'

import { useState } from 'react'

const DebtorsProbabilityOfDefaultReport = ({}) => {
	const { t } = useTranslation()

	const classes = useStyles()
	const [loading, setLoading] = useState(false)

	const downloadList = async () => {
		setLoading(true)

		const res = await axios.get(`${API_URL}${API_DebtorsPDReportExport}`, {
			responseType: 'blob'
		})

		const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
		const blobUrl = URL.createObjectURL(blob)

		const link = document.createElement('a')

		link.href = blobUrl
		link.download = 'debtors_probability_of_default.xlsx'

		document.body.appendChild(link)

		link.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window
			})
		)

		document.body.removeChild(link)

		setLoading(false)
	}

	return (
		<>
			<Box py={2}>
				<h5>
					&emsp; Probability of defaults report for debtors:&ensp;
					<Button onClick={downloadList} variant={'outlined'} disabled={loading}>
						{t('download')}
					</Button>{' '}
				</h5>
			</Box>
		</>
	)
}

export default DebtorsProbabilityOfDefaultReport
