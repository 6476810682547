import { Link } from '@material-ui/core'

const Logo = () => {
	return (
		<Link href="/">
			<img width={'150px'} src="/logo.png" alt={'Enterpay'} />
		</Link>
	)
}

export default Logo
