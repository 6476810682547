import { Box } from '@material-ui/core'
import formatDate from '../../../utils/formatDate'
import { useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import {
	API_DownloadReminder,
	API_ReminderList,
	API_URL,
	API_URL_SERVICE
} from '../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import { useTranslation } from 'react-i18next'
import { formatTaxPrice } from '../../../utils/formatCurrency'
import DetailRow from '../../../components/shared/DetailTable/DetailRow'
import DetailTable from '../../../components/shared/DetailTable/DetailTable'
import useStyles from './ReminderDetail.styles'

const ReminderInfos = (props) => {
	const { inv_id } = props
	const classes = useStyles()
	const { t } = useTranslation()

	const [detail, setDetail] = useState({
		id: '',
		createdAt: ''
	})

	useEffect(() => {
		;(async () => {
			try {
				let res = await axios.get(`${API_URL}${API_ReminderList}/${inv_id}`)
				let detailData = res.data
				setDetail(camelcaseKeys(detailData, { deep: true }))
			} catch (error) {
				console.log('loading error', error)
			}
		})()
	}, [inv_id])

	return (
		<>
			<Box mt={2} mb={4}>
				<DetailTable title={'general.basicInformation'}>
					<DetailRow
						name={'reminders.reminderDownload'}
						value={
							detail['pdfFilePath'] !== null ? (
								<a
									href={
										`${API_URL_SERVICE}${API_DownloadReminder}` + detail['uuid']
									}
									target="_blank"
								>
									{t('download')}
								</a>
							) : (
								'Reminder not available'
							)
						}
					/>
					<DetailRow
						name={'date'}
						value={detail['createdAt'] !== '' ? formatDate(detail['createdAt']) : ''}
					/>
					<DetailRow
						name={'reminders.additionalFee'}
						value={
							formatTaxPrice(detail['additionalFee']) !== ''
								? formatTaxPrice(detail['additionalFee'])
								: ''
						}
					/>

					<DetailRow
						name={'reminders.amount'}
						value={
							formatTaxPrice(detail['amount']) !== ''
								? formatTaxPrice(detail['amount'])
								: ''
						}
					/>

					<DetailRow
						name={'reminders.reminderType'}
						value={detail['reminderType'] !== '' ? detail['reminderType'] : ''}
					/>
				</DetailTable>
			</Box>
		</>
	)
}

export default ReminderInfos
