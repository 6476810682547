import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	link: {
		fontSize: '0.875rem',
		fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
		fontWeight: 500,
		lineHeight: 1.57,
		color: 'grey'
	},
	detailHeader: {
		fontWeight: 'bold',
		fontSize: '12pt',
		color: '#606060'
	},
	gridContainer: {
		marginTop: theme.spacing(2)
	},
	gridItems: {
		marginTop: theme.spacing(1)
	},
	detailCaption: {
		fontWeight: 'bold',
		color: '#606060'
	},
	detailInfo: {

	},
	codePart: {
		background: '#dfdfdf',
		padding: '20px',
		borderRadius: '11px'
	},
	tabs: {
		marginTop: theme.spacing(4)
	},
	tab: {
		paddingLeft: 0,
		paddingRight: 0
	}
}))

export default useStyles
