import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'

import { Typography, Breadcrumbs, Button, Box } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import MerchantListTable from './MerchantListTable'
import useStyles from './MerchantList.styles'

const MerchantList = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const classes = useStyles()

	const handleAddCompanyClick = () => {
		navigate('/merchants/add')
	}

	const handleAddOrganizationClick = () => {
		navigate('/organizations/add')
	}

	return (
		<>
			<div className={classes.header}>
				<Typography variant={'h3'} gutterBottom>
					{t('merchant_list')}
				</Typography>


				<Box ml={'auto'} display={'flex'} gridGap={24}>
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddOrganizationClick}
						className={classes.companyAddButton}
					>
						{t('organization.add')}
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddCompanyClick}
						className={classes.companyAddButton}
					>
						{t('merchant.add')}
					</Button>
				</Box>
			</div>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					{t('dashboard')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('merchant_list')}
				</Typography>
			</Breadcrumbs>

			<MerchantListTable />
		</>
	)
}

export default MerchantList
