import { Box, Button, Dialog, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import axios from '../../../../../utils/axios'
import { API_URL } from '../../../../../config/constants'
import { SnackbarContext } from '../../../../../context'
import { useTranslation } from 'react-i18next'

const OrganizationImport = ({ isImportOpen, handleImportClose }) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [loading, setLoading] = useState(false)
	const [file, setFile] = useState()

	const handleFileChange = (e) => {
		setFile(e.target.files[0])
	}

	const handleUpload = async (e) => {
		e.preventDefault()

		setLoading(true)

		const formData = new FormData()
		formData.append('csv', file)

		try {
			await axios.post(`${API_URL}/whitelist/csv`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})

			showMessage(t('success'), 'success')
		} catch (e) {
			showMessage(t('errors.500'), 'error')
		}

		setLoading(false)
	}

	return (
		<Dialog open={isImportOpen} onClose={handleImportClose} maxWidth={'md'}>
			<DialogTitle id="simple-modal-title">Import</DialogTitle>

			<DialogContent>
				<form onSubmit={handleUpload}>
					<List dense={true}>
						<ListItem>
							<label>
								CSV
								<input type="file" accept=".csv" onChange={handleFileChange} />
							</label>
						</ListItem>

						<ListItem>
							Crefo ID, Company, Firstname, Name, Post Code, City, Address, Country,
							Limit, VAT ID, Email, Phone
						</ListItem>
						<ListItem>
							uploading large number of debtors is suggested to be done only outside
							business hours
						</ListItem>

						<ListItem>how it works:</ListItem>
						<List
							dense={true}
							disablePadding={true}
							style={{
								paddingLeft: 24
							}}
						>
							<ListItem>
								Check if there are already organizations with the same name or
								identification number in the system.
							</ListItem>
							<ListItem>
								If duplicates are found we check that the credit limit matches with
								the requested credit limit.
							</ListItem>

							<List>
								<ListItem>
									if crefo id is known, we add a new secondary credit limit that
									is used when brief report is not found
								</ListItem>
								<ListItem>
									if crefo id is not known and company is whitelisted, we simply
									check that the limit in the document is the same as it was
									earlier.
								</ListItem>
								<ListItem>if there is nothing to modify we skip the row.</ListItem>
							</List>

							<ListItem>
								Find more details about the company if the identification number
								(crefo id) is missing from the csv
							</ListItem>
							<ListItem>
								A company is whitelisted and indexed to search when the
								identification number (crefo id) is found during the process of
								searching for the company's details. If the crefo id is retrieved
								successfully, the organization is marked as not whitelisted and
								indexed for searching.
							</ListItem>
							<ListItem>
								if Crefo id is found and company has a positive credit limit in the
								request, a secondary limit is added
							</ListItem>
						</List>
					</List>

					<Box my={2} display={'flex'} justifyContent={'center'} gridGap={24}>
						<Button
							onClick={handleImportClose}
							color={'primary'}
							variant={'outlined'}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							type={'submit'}
							color={'primary'}
							variant={'contained'}
							disabled={loading || !file}
						>
							Import
						</Button>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default OrganizationImport
