import { useTranslation } from 'react-i18next'

import formatDate from '../../../utils/formatDate'
import { API_UncapturedPurchases, API_UncapturedPurchasesExport } from '../../../config/constants'

import { formatEurocentsToEuro, formatQuote } from '../../../utils/formatCurrency'
import {SortableTableUncapturedPurchases } from '../../../components/shared/SortableTable'
import {

	TableCell
} from '@material-ui/core'

const UncapturedPurchasesTable = ({}) => {

    const { t } = useTranslation()


    const renderBodyCells = (row, rowIndex) => {
        return headCells.map((cell) => {
            if (cell.id === 'id') {
                // Render ID column as a link
                return (
                    <TableCell key={cell.id}>
                        <a href={`../../purchases/detail/${row[cell.id]}`}>
                            {row[cell.id]}
                        </a>
                    </TableCell>
                );
            } else {
                // Render other columns normally
                return (
                    <TableCell key={cell.id}>
                        {cell.format ? cell.format(row[cell.id]) : row[cell.id]}
                    </TableCell>
                );
            }
        });
    };


    const headCells = [
        { id: 'id', label: t('report.purchaseId') },
        { id: 'createdAt', label: t('report.createdAt') },
		{ id: 'debtorName', label: t('report.debtorName')},
        { id: 'buyerName', label: t('report.buyerName')},
        { id: 'merchantName', label: t('report.merchantName')},
        { id: 'uncapturedAmount', label: t('report.uncapturedAmount'), format: formatEurocentsToEuro }
    ]

        return (
            <SortableTableUncapturedPurchases
                apiUrl={API_UncapturedPurchases}
                headCells={headCells}
                downloadUrl={API_UncapturedPurchasesExport}
                renderBodyCells={renderBodyCells}
            />
        )
    }

    export default UncapturedPurchasesTable