import { useTranslation } from 'react-i18next'
import {
	API_MonthlyInvoiceVolumeReport,
	API_MonthlyInvoiceVolumeReportExport
} from '../../../config/constants'

import { formatEurocentsToEuro, formatQuote } from '../../../utils/formatCurrency'
import { SortableTableDownload } from '../../../components/shared/SortableTable'

const MonthlyInvoiceReportTable = () => {
	const { t } = useTranslation()

	const headCells = [
		{ id: 'invoiceAmount', label: t('report.invoiceAmount'), format: formatEurocentsToEuro },
		{ id: 'reminderAmount', label: t('report.reminderAmount'), format: formatEurocentsToEuro },
		{ id: 'dunningAmount', label: t('report.dunningAmount'), format: formatEurocentsToEuro },
		{
			id: 'secondDunningAmount',
			label: t('report.secondDunningAmount'),
			format: formatEurocentsToEuro
		},
		{ id: 'secondDunningQuote', label: t('report.secondDunningQuote'), format: formatQuote },
		{ id: 'paidAmount', label: t('report.paidAmount'), format: formatEurocentsToEuro },
		{ id: 'paidQuote', label: t('report.paidQuote'), format: formatQuote }
	]

	return (
		<SortableTableDownload
			apiUrl={API_MonthlyInvoiceVolumeReport}
			headCells={headCells}
			downloadUrl={API_MonthlyInvoiceVolumeReportExport}
		/>
	)
}

export default MonthlyInvoiceReportTable
