import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { API_OrganizationList, API_UserList } from '../../../../config/constants'

import { TableCell, Button } from '@material-ui/core'

import formatDate from '../../../../utils/formatDate'
import { SortableTable } from '../../../../components/shared/SortableTable'
import { formatTaxPrice } from '../../../../utils/formatCurrency'
import { useActiveTab } from '../../../../hooks'

const OrganizationUserListTable = ({ org_id, active }) => {
	const loadData = useActiveTab(active)
	const navigate = useNavigate()
	const { t } = useTranslation()

	const handleClickEdit = (id) => {
		navigate(`/organizations/edit/${id}`)
	}

	const headCells = [
		{ id: 'name', label: t('general.name') },
		{ id: 'email', label: t('general.email'), sortable: false },
		{ id: 'mobile', label: t('general.phoneNumber'), sortable: false },
		{ id: 'creditLimit', label: t('user.creditLimit') },
		{ id: 'status', label: t('general.status'), sortable: false },
		{ id: 'userJoinedOrg', label: t('user.userJoinedOrg') },
		{ id: 'edit', label: t('edit'), sortable: false }
	]

	const renderBodyCells = (result, i) => {
		return (
			<>
				<TableCell>
					{result.salutation} {result.name}
				</TableCell>
				<TableCell>{result.primaryEmail}</TableCell>
				<TableCell>{result.phoneNumber}</TableCell>
				<TableCell>{formatTaxPrice(result.creditLimit)}</TableCell>
				<TableCell>{result['isActivated'] === 1 ? 'true' : 'false'}</TableCell>
				<TableCell>{formatDate(result.userJoinedOrg)}</TableCell>
				<TableCell>
					<Button onClick={() => handleClickEdit(result.id)}>Edit</Button>
				</TableCell>
			</>
		)
	}

	return loadData ? (
		<>
			<SortableTable
				apiUrl={`${API_OrganizationList}/${org_id}${API_UserList}`}
				headCells={headCells}
				renderBodyCells={renderBodyCells}
				order={'name'}
			/>
		</>
	) : (
		<></>
	)
}

export default OrganizationUserListTable
