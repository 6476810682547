import { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Box, Breadcrumbs, Typography, Tab, Tabs } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { useTranslation } from 'react-i18next'
import UserInfos from './UserInfos'
import UserCompanies from './UserCompanies'
import useStyles from './UserDetail.styles'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box>{children}</Box>
		</Typography>
	)
}

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	}
}

const UserDetail = () => {
	const [value, setValue] = useState(0)
	const { t } = useTranslation()

	function handleChange(event, newValue) {
		setValue(newValue)
	}

	const params = useParams()

	const classes = useStyles()

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('user.userDetails')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					{t('dashboard')}
				</Link>
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/users">
					{t('users')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('details')}
				</Typography>
			</Breadcrumbs>

			<Tabs
				className={classes.tabs}
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				aria-label="scrollable auto tabs example"
			>
				<Tab
					className={classes.tab}
					label={t('users.detail.information')}
					{...a11yProps(0)}
				/>
				<Tab
					className={classes.tab}
					label={t('users.detail.companies')}
					{...a11yProps(1)}
				/>
			</Tabs>

			<TabPanel value={value} index={0}>
				<UserInfos user_id={params.id} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<UserCompanies user_id={params.id} />
			</TabPanel>
		</>
	)
}

export default UserDetail
