import { createContext, useState } from 'react'

export const UserContext = createContext({
	setUser: () => {},
	user: null,
	isAdmin: () => {}
})

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null)

	const isInGroup = (group) => user?.groups?.indexOf(group) > -1
	const isAdmin = () => isInGroup('admin')

	return (
		<UserContext.Provider value={{ user, setUser, isAdmin }}>{children}</UserContext.Provider>
	)
}
