import { useTranslation } from 'react-i18next'

import formatDate from '../../../utils/formatDate'

import { API_PurchaseList } from '../../../config/constants'

import { formatTaxPrice } from '../../../utils/formatCurrency'
import { SortableTable } from '../../../components/shared/SortableTable'

const PurchaseListTable = ({}) => {
	const { t } = useTranslation()

	const headCells = [
		{ id: 'id', label: 'No' },
		{ id: 'merchantName', label: t('purchase.merchantName') },
		{ id: 'purchaseStatus', label: t('purchase.purchaseStatus') },
		{ id: 'orgName', label: t('purchase.orgName') },
		{ id: 'userName', label: t('purchase.userName') },
		{ id: 'totalPriceTaxed', label: t('purchase.totalPriceTaxed'), format: formatTaxPrice },
		{ id: 'createdAt', label: t('purchase.date'), format: formatDate }
	]

	return (
		<SortableTable
			apiUrl={API_PurchaseList}
			detailUrl="/purchases/detail"
			headCells={headCells}
		/>
	)
}

export default PurchaseListTable
