import { useTranslation } from 'react-i18next'
import { useActiveTab } from '../../../../hooks'
import formatDate from '../../../../utils/formatDate'
import { SortableTable } from '../../../../components/shared/SortableTable'
import { API_TransactionList, API_PurchaseList } from '../../../../config/constants'
import {formatTaxPrice} from "../../../../utils/formatCurrency";

const PurchaseTransactionsList = ({ pur_id, active }) => {
	const { t } = useTranslation()
	const loadData = useActiveTab(active)

	const headCells = [
		{ id: 'id', label: 'ID' },
		{ id: 'name', label: t('transaction.merchantName'), width: 25 },
		{ id: 'purchaseId', label: t('general.purchaseId') },
		{ id: 'orgName', label: t('transaction.orgName') },
		{ id: 'totalPriceTaxed', label: t('transaction.totalPriceTaxed'), format: formatTaxPrice},
		{ id: 'transactionType', label: t('transaction.transactionType') },
		{ id: 'createdAt', label: t('transaction.date'), format: formatDate }
	]

	return loadData ? (
		<SortableTable
			apiUrl={`${API_PurchaseList}/${pur_id}${API_TransactionList}`}
			detailUrl="/transactions/detail"
			headCells={headCells}
			order={'name'}
		/>
	) : (
		<></>
	)
}

export default PurchaseTransactionsList
