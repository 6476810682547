const formatDate = (date, withTime = true) => {
	if (!date) return ''

	const format = 'en-GB'

	const d = new Date(date)

	if (withTime) {
		return d.toLocaleString(format)
	} else {
		return d.toLocaleDateString(format)
	}
}

export default formatDate
