import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import formatDate from '../../../utils/formatDate'

import { API_MerchatList } from '../../../config/constants'

import { TableCell, Button } from '@material-ui/core'

import { SortableTable } from '../../../components/shared/SortableTable'

const MerchantListTable = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const gotoResultDetail = (id) => {
		navigate(`/merchants/detail/${id}`)
	}

	const handleClickEdit = (id) => {
		navigate(`/merchants/edit/${id}`)
	}

	const headCells = [
		{ id: 'name', label: t('merchant.name') },
		{ id: 'tebaMerchantId', label: t('merchant.tebaMerchantId') },
		{ id: 'Active', label: t('merchant.status') },
		{ id: 'createdAt', label: t('date') },
		{
			id: 'edit',
			label: '',
			sortable: false
		}
	]

	const renderBodyCells = (result) => {
		return (
			<>
				<TableCell onClick={() => gotoResultDetail(result.id)}>{result.name}</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>
					{result.tebaMerchantId}
				</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>
					{result.deactivated === false ? 'Active' : 'Deactive'}
				</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>
					{formatDate(result.createdAt)}
				</TableCell>
				<TableCell>
					<Button variant={'outlined'} onClick={() => handleClickEdit(result.id)}>
						{t('edit')}
					</Button>
				</TableCell>
			</>
		)
	}

	return (
		<>
			<SortableTable
				apiUrl={API_MerchatList}
				detailUrl="/merchants/detail"
				headCells={headCells}
				renderBodyCells={renderBodyCells}
			/>
		</>
	)
}

export default MerchantListTable
