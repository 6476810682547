import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Box, Typography, Breadcrumbs, Button } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import TransactionListTable from './TransactionListTable'
import useStyles from './TransactionList.styles'
import axios from '../../../utils/axios'

import { API_TransactionListExport, API_URL } from '../../../config/constants'

import { useState } from 'react'

const TransactionList = () => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState(false)

	const classes = useStyles()

	const downloadList = async () => {
		setLoading(true)

		const res = await axios.get(`${API_URL}${API_TransactionListExport}`, {
			responseType: 'blob'
		})

		const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
		const blobUrl = URL.createObjectURL(blob)

		const link = document.createElement('a')

		link.href = blobUrl
		link.download = 'transactions.xlsx'

		document.body.appendChild(link)

		link.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window
			})
		)

		document.body.removeChild(link)

		setLoading(false)
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('transaction_list')}
				</Typography>
			</Box>

			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
				<Breadcrumbs
					separator={<NavigateNextIcon fontSize="small" />}
					aria-label="breadcrumb"
				>
					<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
						{t('dashboard')}
					</Link>
					<Typography variant={'subtitle2'} color="textPrimary">
						{t('transaction_list')}
					</Typography>
				</Breadcrumbs>

				<Button onClick={downloadList} variant={'outlined'} disabled={loading}>
					{t('download')}
				</Button>
			</Box>

			<TransactionListTable />
		</>
	)
}

export default TransactionList
