import { useTranslation } from 'react-i18next'

import { API_TransactionList, API_Items } from '../../../../config/constants'
import { formatTaxPrice } from '../../../../utils/formatCurrency'

import { TableCell } from '@material-ui/core'

import { SortableTable } from '../../../../components/shared/SortableTable'
import { useActiveTab } from '../../../../hooks'

const TransactionItemsListTable = ({ tran_id, active }) => {
	const loadData = useActiveTab(active)
	const { t } = useTranslation()

	const headCells = [
		{ id: 'no', label: 'No', sortable: false },
		{ id: 'name', label: t('item.name') },
		{ id: 'quantity', label: t('item.quantity') },
		{ id: 'currency', label: t('item.currency') },
		{ id: 'unitPriceTaxFree', label: t('item.unitPriceTaxFree') },
		{ id: 'unitPriceTaxed', label: t('item.unitPriceTaxed') },
		{ id: 'originalPriceTaxed', label: t('item.originalPriceTaxed') },
		{ id: 'totalPriceTaxFree', label: t('item.totalPriceTaxFree') },
		{ id: 'totalPriceTaxed', label: t('item.totalPriceTaxed') }
	]

	const renderBodyCells = (result, i) => {
		return (
			<>
				<TableCell>{i + 1}</TableCell>
				<TableCell>{result.name}</TableCell>
				<TableCell>{result.quantity}</TableCell>
				<TableCell>{result.currency}</TableCell>
				<TableCell>{formatTaxPrice(result.unitPriceTaxFree)}</TableCell>
				<TableCell>{formatTaxPrice(result.unitPriceTaxed)}</TableCell>
				<TableCell>{formatTaxPrice(result.originalPriceTaxed)}</TableCell>
				<TableCell>{formatTaxPrice(result.totalPriceTaxFree)}</TableCell>
				<TableCell>{formatTaxPrice(result.totalPriceTaxed)}</TableCell>
			</>
		)
	}

	return loadData ? (
		<SortableTable
			apiUrl={`${API_TransactionList}/${tran_id}${API_Items}`}
			headCells={headCells}
			order={'name'}
			renderBodyCells={renderBodyCells}
		/>
	) : (
		<></>
	)
}

export default TransactionItemsListTable
