import React, { useEffect, useState } from 'react'

import axios from '../../../../utils/axios'
import { API_EcomMerchantList, API_URL } from '../../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import { Box, Button, Grid, TextField, FormLabel } from '@material-ui/core'
import { useActiveTab } from '../../../../hooks'
import useStyles from '../MerchantDetail.styles'

const EcomMerchantList = ({ mer_id, active }) => {
	const loadData = useActiveTab(active)
	const classes = useStyles()
	const [detail, setDetail] = useState({
		username: '',
		password: '',
		merchantId: ''
	})
	const [status, setStatus] = useState(false)
	const [passValid, setPassValid] = useState(false)
	const [usernameValid, setUsernameValid] = useState(false)

	useEffect(() => {
		if (!mer_id || !loadData) return
		;(async () => {
			try {
				let res = await axios.get(`${API_URL}${API_EcomMerchantList}/${mer_id}`)
				let detailData = res.data
				setDetail(camelcaseKeys(detailData, { deep: true }))
			} catch (error) {
				console.log('loading error', error)
			}
		})()
	}, [mer_id, loadData])

	useEffect(() => {
		validateFields()
	}, [passValid, usernameValid])

	const handleChangeStatus = () => {
		setUsernameValid(false)
		setPassValid(false)
		validateFields()
		setStatus(!status)
	}

	const validateFields = () => {
		if (detail.username && detail.username != '') {
			setUsernameValid(true)
		} else {
			setUsernameValid(false)
		}

		if (detail.password && detail.password != '') {
			setPassValid(true)
		} else {
			setPassValid(false)
		}
	}

	const handleClickSave = () => {
		validateFields()

		if (usernameValid && passValid) {
			console.log('passed!')
			axios
				.post(`${API_URL}${API_EcomMerchantList}/${mer_id}`, detail)
				.then(({ data }) => {
					setDetail(camelcaseKeys(data, { deep: true }))
					setStatus(false)
				})
				.catch((e) => {
					setStatus(false)
				})
		}
	}

	const handleChangeFormData = (event, formName) => {
		switch (formName) {
			case 'username':
				setDetail({ ...detail, username: event.target.value })
				validateFields()
				break
			case 'password':
				setDetail({ ...detail, password: event.target.value })
				validateFields()
				break
			default:
				break
		}
	}

	return (
		<>
			<Box mt={3}>
				<Grid className={classes.gridContainer} container>
					<Grid item xs={6} sm={6}>
						{status ? (
							<>
								<Button
									variant="outlined"
									color="primary"
									onClick={() => handleClickSave()}
								>
									Save
								</Button>
								<Button
									variant="outlined"
									color="primary"
									onClick={() => handleChangeStatus()}
								>
									Cancel Edit
								</Button>
							</>
						) : (
							<Button
								variant="outlined"
								color="primary"
								onClick={() => handleChangeStatus()}
							>
								{detail.username && detail.username != ''
									? `Edit Credential`
									: `Add Credential`}
							</Button>
						)}
					</Grid>
				</Grid>
				<Grid className={classes.gridContainer} container>
					<Grid item xs={6} sm={6}>
						<span className={classes.detailCaption}>Username</span>
					</Grid>
					<Grid item xs={6} sm={6}>
						{!status ? (
							<span className={classes.detailInfo}>{detail.username}</span>
						) : (
							<>
								<TextField
									fullWidth
									required
									onChange={(e) => handleChangeFormData(e, 'username')}
									label="User Name"
									value={detail['username']}
								/>
								{!usernameValid ? (
									<FormLabel error>Username Must Not Be Blank!</FormLabel>
								) : (
									``
								)}
							</>
						)}
					</Grid>
				</Grid>
				<Grid className={classes.gridItems} container>
					<Grid item xs={6} sm={6}>
						<span className={classes.detailCaption}>Password</span>
					</Grid>
					<Grid item xs={6} sm={6}>
						{!status ? (
							<span className={classes.detailInfo}>{detail.password}</span>
						) : (
							<>
								<TextField
									type={'password'}
									fullWidth
									required
									onChange={(e) => handleChangeFormData(e, 'password')}
									label="Password"
									value={detail['password']}
								/>
								{!passValid ? (
									<FormLabel error>Password Not Be Blank!</FormLabel>
								) : (
									``
								)}
							</>
						)}
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

export default EcomMerchantList
