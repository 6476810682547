import { Box } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import axios from '../../../../utils/axios'
import { API_PurchaseList, API_URL } from '../../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import { SnackbarContext, UserContext } from '../../../../context'
import { useTranslation } from 'react-i18next'
import DetailTable from '../../../../components/shared/DetailTable/DetailTable'
import DetailRow from '../../../../components/shared/DetailTable/DetailRow'
import { formatTaxPrice } from '../../../../utils/formatCurrency'
import { useActiveTab } from '../../../../hooks'
import { Button } from '@material-ui/core'
import useStyles from './PurchaseInfo.styles'

const PurchaseInfo = ({ pur_id, active }) => {
	const { t } = useTranslation()

	const { showMessage } = useContext(SnackbarContext)

	const [detail, setDetail] = useState({})
	const loadData = useActiveTab(active)
	const { user } = useContext(UserContext)
	const classes = useStyles()

	useEffect(() => {
		if (!pur_id || !loadData) return
		;(async () => {
			try {
				let res = await axios.get(`${API_URL}${API_PurchaseList}/${pur_id}`)
				let detailData = res.data
				console.log(detailData)
				setDetail(camelcaseKeys(detailData, { deep: true }))
			} catch (error) {
				console.log('loading error', error)
				showMessage(t('errors.500'), 'error')
			}
		})()
	}, [pur_id, loadData])

	const handleClickExpire = async () => {
		try {
			await axios.put(`${API_URL}${API_PurchaseList}/${pur_id}/expire/${user.username}`)

			showMessage('Saved', 'success')
		} catch (e) {
			console.log('failed', e)
			showMessage(t('errors.expiration_not_possible'), 'error')
		}
	}

	return (
		<Box mt={2} mb={4}>
			<DetailTable title={'general.basicInformation'}>
				<DetailRow name={'purchase.merchantName'} value={detail['merchantName']} />
				<DetailRow name={'purchase.tebaMerchantId'} value={detail['tebaMerchantId']} />
				<DetailRow name={'general.status'} value={detail['purchaseStatus']} />
				<DetailRow
					name={'totalPriceTaxed'}
					value={formatTaxPrice(detail['totalPriceTaxed'])}
				/>
				<DetailRow name={'purchase.vatId'} value={detail['vatId']} />
				<DetailRow
					name={'purchase.registrationNumber'}
					value={detail['registrationNumber']}
				/>
				<DetailRow name={'purchase.crefoId'} value={detail['crefoId']} />
				<DetailRow name={'purchase.tebaClientId'} value={detail['tebaClientId']} />
				<DetailRow
					name={'companyName'}
					value={
						<a href={'../../organizations/detail/' + detail['orgId']}>
							{t(detail['orgName'])}{' '}
						</a>
					}
				/>
				<DetailRow
					name={'userName'}
					value={
						<a href={'../../users/detail/' + detail['userId']}>
							{t(detail['userName'])}{' '}
						</a>
					}
				/>
				<DetailRow name={'general.creationDate'} value={detail['createdAt']} />
			</DetailTable>
			{detail['isExpirable'] ? (
				<Button
					variant="contained"
					color="primary"
					className={classes.cancelButton}
					onClick={() => handleClickExpire(pur_id)}
				>
					{t('purchase.expire_purchase')}
				</Button>
			) : (
				<></>
			)}
		</Box>
	)
}

export default PurchaseInfo
