import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {Box, Typography, Breadcrumbs, makeStyles} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React from "react";

const useStyles = makeStyles((theme) => ({
	link: {
		fontSize: '0.875rem',
		fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
		fontWeight: 500,
		lineHeight: 1.57,
		color: 'grey'
	}
}))

const Admin = () => {
	const { t } = useTranslation()
	const classes = useStyles()
	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('admin')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					{t('dashboard')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('admin')}
				</Typography>
			</Breadcrumbs>
			{t("in_progress")}
		</>
	)
}

export default Admin
