import { useTranslation } from 'react-i18next'

import formatDate from '../../../utils/formatDate'
import { formatTaxPrice } from '../../../utils/formatCurrency'

import { API_ReminderList } from '../../../config/constants'

import { SortableTable } from '../../../components/shared/SortableTable'

const ReminderListTable = ({}) => {
	const { t } = useTranslation()

	const headCells = [
		{ id: 'id', label: 'No' },
		{ id: 'amount', label: t('reminders.amount'), format: formatTaxPrice },
		{ id: 'invoiceId', label: t('reminders.invoiceId') },
		{ id: 'reminderType', label: t('reminders.reminderType') },
		{ id: 'createdAt', label: t('date'), format: formatDate }
	]

	return (
		<>
			<SortableTable
				apiUrl={API_ReminderList}
				detailUrl="/reminders/detail"
				headCells={headCells}
			/>
		</>
	)
}

export default ReminderListTable
