import './Home.css'

import React, { useState } from 'react'
import { Box, Typography, Tab, Tabs } from '@material-ui/core'
import MonthlyVolumeReport from 'pages/statistics/MonthlyVolume/MonthlyVolumeReport'
import TransactionActivityReport from 'pages/statistics/TransactionActivity/TransactionActivityReport'
import MonthlyInvoiceVolumeReport from 'pages/statistics/MonthlyInvoiceVolume/MonthlyInvoiceVolumeReport'
import DunningsReport from 'pages/statistics/Dunnings/DunningsReport'
import UncapturedPurchasesReport from 'pages/statistics/UncapturedPurchases/UncapturedPurchasesReport'
import ProbabilityOfDefaultsReport from 'pages/statistics/ProbabilityOfDefaults/ProbabilityOfDefaultsReport'
import { useTranslation } from 'react-i18next'
import WeeklyReports from './pages/statistics/WeeklyReports/WeeklyReports'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box>{children}</Box>
		</Typography>
	)
}

const Home = () => {
	const [value, setValue] = useState(0)
	function handleChange(event, newValue) {
		setValue(newValue)
	}

	const { t } = useTranslation()
	return (
		<>
			<Box mb={4}>
				<Typography variant={'overline'}>Overview</Typography>
				<Typography variant={'h3'} gutterBottom={true}>
					Statistic
				</Typography>

				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
				>
					<Tab label={t('report.monthly_volume_report')} />
					<Tab label={t('report.transaction_activity')} />
					<Tab label={t('report.monthly_invoices_volume_report')} />
					<Tab label={t('report.dunnings')} />
					<Tab label={t('report.uncaptured_purchases')} />
					<Tab label={t('report.probability_of_defaults')} />
					<Tab label={t('report.weekly')} />
				</Tabs>

				<TabPanel value={value} index={0}>
					<MonthlyVolumeReport active={value === 0} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<TransactionActivityReport active={value === 1} />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<MonthlyInvoiceVolumeReport active={value === 2} />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<DunningsReport active={value === 3} />
				</TabPanel>
				<TabPanel value={value} index={4}>
					<UncapturedPurchasesReport active={value === 4} />
				</TabPanel>
				<TabPanel value={value} index={5}>
					<ProbabilityOfDefaultsReport active={value === 5} />
				</TabPanel>
				<TabPanel value={value} index={6}>
					<WeeklyReports active={value === 6} />
				</TabPanel>
			</Box>
		</>
	)
}

export default Home
