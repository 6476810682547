import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Box, Breadcrumbs, Typography, Tab, Tabs } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import OrganizationInfos from './OrganizationInfos'
import { useTranslation } from 'react-i18next'
import OrganizationUserListTable from './OrganisationUsers/OrganizationUserListTable'
import OrganizationHistoryListTable from './OrganizationHistoryList/OrganizationHistoryListTable'
import OrganizationWhitelist from './OrganizationWhitelist'
import axios from '../../../utils/axios'
import { API_OrganizationList, API_URL } from '../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import useStyles from './OrganizationDetail.styles'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box>{children}</Box>
		</Typography>
	)
}

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	}
}

const OrganizationDetail = () => {
	const { t } = useTranslation()
	const [value, setValue] = useState(0)

	const params = useParams()

	const classes = useStyles()

	const [detail, setDetail] = useState({
		id: '',
		businessId: '',
		vatId: '',
		name: '',
		tebaClientId: '',
		country: '',
		registrationNumber: '',
		currency: '',
		billingStreet: '',
		billingStreetSecondRow: '',
		billingPcode: '',
		billingCity: '',
		billingCountryCode: '',
		email: '',
		mobile: '',
		contactLanguage: '',
		website: '',
		regOffice: '',
		deactivated: '',
		createdAt: '',
		updatedAt: '',
		taxCode: '',
		creditLimit: '',
		shouldIndexToSearch: '',
		shouldRestrictInvoicingAddress: '',
		invoices: [],
		remainingCreditLimit: '',
		organisationHistories: []
	})

	useEffect(() => {
		if (!params.id) return
		;(async () => {
			try {
				let res = await axios.get(`${API_URL}${API_OrganizationList}/${params.id}`)
				let detailData = res.data
				setDetail({
					...camelcaseKeys(detailData, { deep: true }),
					creditLimit: detailData.creditLimit
				})
			} catch (error) {
				console.log('loading error', error)
			}
		})()
	}, [params.id])

	function handleChange(event, newValue) {
		setValue(newValue)
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('organization.detail')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link className={classes.link} color="inherit" variant={'subtitle2'} to="/">
					{t('dashboard')}
				</Link>
				<Link
					className={classes.link}
					color="inherit"
					variant={'subtitle2'}
					to="/organizations"
				>
					{t('organizations')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('detail')}
				</Typography>
			</Breadcrumbs>

			<Tabs
				className={classes.tabs}
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				aria-label="scrollable auto tabs example"
			>
				<Tab
					className={classes.tab}
					label={t('organization.detail.information')}
					{...a11yProps(0)}
				/>
				<Tab
					className={classes.tab}
					label={t('organization.detail.users')}
					{...a11yProps(1)}
				/>
				<Tab
					className={classes.tab}
					label={t('organization.detail.history')}
					{...a11yProps(2)}
				/>
				<Tab
					className={classes.tab}
					label={t('organization.detail.whitelist')}
					{...a11yProps(2)}
				/>
			</Tabs>

			<TabPanel value={value} index={0}>
				<OrganizationInfos data={detail} org_id={params.id} active={value === 0} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<OrganizationUserListTable org_id={params.id} active={value === 1} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<OrganizationHistoryListTable org_id={params.id} active={value === 2} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<OrganizationWhitelist
					org_id={params.id}
					active={value === 3}
					businessId={detail.businessId}
				/>
			</TabPanel>
		</>
	)
}

export default OrganizationDetail
