import { useTranslation } from 'react-i18next'

import { API_OrganizationList, API_URL } from '../../../../config/constants'

import { useActiveTab } from '../../../../hooks'
import {
	Box,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TableSortLabel
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import OrganizationWhitelistEmailForm from './components/OrganizationWhitelistEmailForm'
import OrganizationWhitelistDomainForm from './components/OrganizationWhitelistDomainForm'
import OrganizationWhitelistPhoneForm from './components/OrganizationWhitelistPhoneForm'
import OrganizationWhitelistLimitForm from './components/OrganizationWhitelistLimitForm'
import axios from '../../../../utils/axios'
import camelcaseKeys from 'camelcase-keys'
import { SnackbarContext } from '../../../../context'

const OrganizationWhitelist = ({ org_id, businessId, active }) => {
	const [loading, setLoading] = useState(false)
	const { showMessage } = useContext(SnackbarContext)

	const [data, setData] = useState()
	const { t } = useTranslation()

	const isActive = useActiveTab(active)

	const getData = () => {
		setLoading(true)
		axios
			.get(`${API_URL}${`${API_OrganizationList}/${org_id}/whitelist`}`)
			.then(({ data }) => {
				setData(camelcaseKeys(data, { deep: true }))
			})
			.catch((e) => {
				showMessage(t('errors.500'), 'error')
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (isActive && !data) {
			getData()
		}
	}, [isActive])

	return isActive ? (
		<>
			<Box display={'flex'} gridGap={12} mt={4} mb={4}>
				<OrganizationWhitelistEmailForm orgId={org_id} />
				<OrganizationWhitelistDomainForm orgId={org_id} />
				<OrganizationWhitelistPhoneForm orgId={org_id} />
				<OrganizationWhitelistLimitForm businessId={businessId} />
			</Box>

			{loading && <LinearProgress />}
			<Box display={'flex'} flexDirection={'column'} gridGap={24}>
				<TableContainer component={Paper}>
					<Table aria-label="Companies Table">
						<TableHead>
							<TableRow>
								<TableCell>Email</TableCell>
								<TableCell width={200}>Active</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data &&
								data.whitelistEmails.map((result, i) => (
									<TableRow key={`result-table-row-${i}`}>
										<TableCell>{result.email}</TableCell>
										<TableCell>
											{result.isActive ? 'Activated' : 'Deactivated'}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>

				<TableContainer component={Paper}>
					<Table aria-label="Companies Table">
						<TableHead>
							<TableRow>
								<TableCell>Phone number</TableCell>
								<TableCell width={200}>Active</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data &&
								data.whitelistPhones.map((result, i) => (
									<TableRow key={`result-table-row-${i}`}>
										<TableCell>{result.phoneNumber}</TableCell>
										<TableCell>
											{result.isActive ? 'Activated' : 'Deactivated'}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>

				<TableContainer component={Paper}>
					<Table aria-label="Companies Table">
						<TableHead>
							<TableRow>
								<TableCell>Domain</TableCell>
								<TableCell width={200}>Active</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data &&
								data.whitelistDomains.map((result, i) => (
									<TableRow key={`result-table-row-${i}`}>
										<TableCell>{result.domain}</TableCell>
										<TableCell>
											{result.isActive ? 'Activated' : 'Deactivated'}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>

				<TableContainer component={Paper}>
					<Table aria-label="Companies Table">
						<TableHead>
							<TableRow>
								<TableCell>Secondary limit</TableCell>
								<TableCell width={200}>Active</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data && data.secondaryLimitEntity && (
								<TableRow>
									<TableCell>
										{data.secondaryLimitEntity.secondaryLimit}
									</TableCell>
									<TableCell>
										{data.secondaryLimitEntity.isActive
											? 'Activated'
											: 'Deactivated'}
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	) : (
		<></>
	)
}

export default OrganizationWhitelist
