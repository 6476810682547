import { useTranslation } from 'react-i18next'
import { API_DunningsReport, API_DunningsReportExport } from '../../../config/constants'

import { formatEurocentsToEuro } from '../../../utils/formatCurrency'
import { SortableTableDownload } from '../../../components/shared/SortableTable'

const MonthlyInvoiceReportTable = () => {
	const { t } = useTranslation()

	const headCells = [
		{ id: 'remindersCount', label: t('report.reminders_count') },
		{
			id: 'remindersAmount',
			label: t('report.reminders_amount'),
			format: formatEurocentsToEuro
		},
		{ id: 'dunningsCount', label: t('report.dunnings_count') },
		{ id: 'dunningsAmount', label: t('report.dunnings_amount'), format: formatEurocentsToEuro },
		{ id: 'secondDunningsCount', label: t('report.second_dunnings_count') },
		{
			id: 'secondDunningsAmount',
			label: t('report.second_dunnings_amount'),
			format: formatEurocentsToEuro
		},
		{
			id: 'additionalFeeAmount',
			label: t('report.additional_fee'),
			format: formatEurocentsToEuro
		},
		{ id: 'feePaidAmount', label: t('report.fee_paid'), format: formatEurocentsToEuro }
	]

	return (
		<SortableTableDownload
			apiUrl={API_DunningsReport}
			headCells={headCells}
			downloadUrl={API_DunningsReportExport}
		/>
	)
}

export default MonthlyInvoiceReportTable
