import { Box, Button, CircularProgress, Paper, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from '../../../utils/axios'
import { API_OrganizationList, API_URL } from '../../../config/constants'
import OrganizationForm from './components/OrganizationForm/OrganizationForm'
import OrganizationImport from './components/OrganizationImport/OrganizationImport'
import { SnackbarContext } from '../../../context'
import useStyles from './OrganizationAdd.styles'

const OrganizationAdd = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [organizations, setOrganizations] = useState([])
	const [organization, setOrganization] = useState(null)

	const [searching, setSearching] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')

	const [isImportOpen, setIsImportOpen] = useState(false)

	const [formData, setFormData] = useState({
		name: '',
		businessId: '',
		billingPcode: '',
		billingCity: '',
		regOffice: '',
		billingCountryCode: '',
		billingStreet: '',
		contactLanguage: '',
		country: '',
		creditLimit: 0,
		email: '',
		vatId: '',
		mobile: ''
	})

	useEffect(() => {
		const controller = new AbortController()

		if (searchQuery.length > 0) {
			setSearching(true)

			axios
				.get(`${API_URL}${API_OrganizationList}/search`, {
					params: {
						q: searchQuery
					},
					signal: controller.signal
				})
				.then(function ({ data }) {
					setOrganizations(data?.companyAdress || [])

					console.log('result', data)
					setSearching(false)
				})
				.catch((e) => console.log(e))
		} else {
			setSearching(false)
		}

		return () => {
			controller.abort()
		}
	}, [searchQuery])

	useEffect(() => {
		const controller = new AbortController()

		if (organization) {
			axios
				.get(`${API_URL}${API_OrganizationList}/business-eligibility`, {
					params: {
						crefoId: organization.crefoId
					},
					signal: controller.signal
				})
				.then(function ({ data: { company } }) {
					setFormData({
						businessId: company.crefoId || '',
						vatId: company.vatNumber || '',
						name: company.name || '',
						country: company.countryCode || '',
						billingStreet: company.addresses[0]?.street || '',
						billingPcode: company.addresses[0]?.postalCode || '',
						billingCity: company.addresses[0]?.city || '',
						billingCountryCode: company.addresses[0]?.countryCode || '',
						email: company.primaryEMail || '',
						mobile: company.phoneNumber || '',
						contactLanguage: company.languageCode || '',
						regOffice: '',
						creditLimit: 0
					})
				})
				.catch((e) => console.log(e))
		}

		return () => {
			controller.abort() // <-- 3rd step
		}
	}, [organization])

	const handleNameChange = (e) => {
		setSearchQuery(e.target.value)
	}

	const openImportModal = () => {
		setIsImportOpen(true)
	}

	const handleModalClose = () => {
		setIsImportOpen(false)
	}

	const handleChangeFormData = (prop) => (e) => {
		setFormData({ ...formData, [prop]: e.target.value })
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		try {
			await axios.post(`${API_URL}${API_OrganizationList}`, formData)

			showMessage(t('success'), 'success')
		} catch (e) {
			showMessage(t('errors.500'), 'error')
		}
	}

	return (
		<>
			<OrganizationImport isImportOpen={isImportOpen} handleImportClose={handleModalClose} />

			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'center'}
				gridGap={24}
			>
				<Paper
					component="form"
					classes={{
						root: classes.root
					}}
				>
					<Autocomplete
						id="company-search"
						fullWidth
						value={organization}
						options={organizations}
						filterOptions={(options) => options}
						getOptionLabel={(company) => company.companyName}
						onChange={(event, selected) => setOrganization(selected)}
						onInputChange={handleNameChange}
						loading={searching}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={'Search'}
								variant="outlined"
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{searching ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									)
								}}
							/>
						)}
					/>
				</Paper>

				<Button variant={'contained'} color={'primary'} onClick={openImportModal}>
					{t('organization.import')}
				</Button>
			</Box>

			<OrganizationForm
				formData={formData}
				handleChangeFormData={handleChangeFormData}
				handleSubmit={handleSubmit}
			/>
		</>
	)
}

export default OrganizationAdd
