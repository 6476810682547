import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const DetailTable = ({ title, children }) => {
	const { t } = useTranslation()

	return (
		<Box mt={2} mb={4}>
			<TableContainer component={Paper}>
				<Table aria-label="Companies Table">
					<TableHead>
						<TableRow>
							<TableCell colSpan={2}>{t(title)}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{children}</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default DetailTable
