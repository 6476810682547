import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'

import formatDate from '../../../utils/formatDate'

import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import axios from '../../../utils/axios'
import { API_URL, API_MerchatList } from '../../../config/constants'

import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	LinearProgress,
	InputBase,
	IconButton,
	TableSortLabel,
	Button,
	Grid
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { PAGE_SIZE, ORDER_DIRS } from '../../../config/constants'
import useStyles from './DunningList.styles'

const DunningListTable = ({ setError }) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const classes = useStyles()

	const [merchants, setMerchants] = useState([])
	const [orderBy, setOrderBy] = useState('createdAt')
	const [orderDir, setOrderDir] = useState('Descending')
	const [keyword, setKeyword] = useState('')
	const [offset, setOffset] = useState(0)
	const [loadFinished, setLoadFinish] = useState(false)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		loadMore()
	}, [offset, orderBy, orderDir, keyword])

	const loadMore = () => {
		setLoading(true)
		setLoadFinish(false)

		const params = snakecaseKeys(
			{
				limit: PAGE_SIZE,
				offset,
				orderBy,
				orderDir,
				q: keyword
			},
			{ deep: true }
		)
		console.log(params)

		axios
			.get(`${API_URL}${API_MerchatList}`, { params })
			.then(({ data }) => {
				data = camelcaseKeys(data, { deep: true })
				let temp = merchants
				temp = temp.concat(data)
				console.log('temp', temp)
				setMerchants(temp)
				setLoading(false)
				if (data.length < PAGE_SIZE) setLoadFinish(true)
			})
			.catch((e) => {
				setError(true)
				setLoading(false)
			})
	}

	const resetSearch = () => {
		setOffset(0)
		setMerchants([])
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0].value
		if (value !== keyword) setKeyword(value)
		else if (!offset) {
			loadMore()
		}
		resetSearch()
	}

	const handleSortChange = (newOrderBy) => {
		if (newOrderBy === orderBy)
			setOrderDir(orderDir === ORDER_DIRS.DESC ? ORDER_DIRS.ASC : ORDER_DIRS.DESC)
		else setOrderDir(ORDER_DIRS.ASC)

		setOrderBy(newOrderBy)

		resetSearch()
	}

	const gotoResultDetail = (id) => {
		navigate(`/merchant/edit/${id}`)
	}

	const headCells = [
		{ id: 'name', company: true, label: t('company_name') },
		{ id: 'tebaMerchantId', label: t('tebaMerchantId') },
		{ id: 'Active', label: t('active') },
		{ id: 'created_at', label: t('date') }
	]

	return (
		<>
			<Paper
				elevation={1}
				component="form"
				className={classes.root}
				onSubmit={handleSearchFormSubmit}
			>
				<InputBase
					placeholder="Search with keywords"
					inputProps={{ 'aria-label': 'search google maps' }}
				/>
				<IconButton type="submit" className="iconButton" aria-label="search">
					<SearchIcon />
				</IconButton>
			</Paper>
			<TableContainer component={Paper}>
				<Table aria-label="Companies Table">
					<TableHead>
						<TableRow>
							<TableCell>
								<TableSortLabel>No</TableSortLabel>
							</TableCell>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									sortDirection={orderBy === headCell.id ? orderDir : false}
								>
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? orderDir : 'Ascending'}
										onClick={() => {
											handleSortChange(headCell.id, headCell.company)
										}}
									>
										{headCell.label}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{merchants.map((result, i) => (
							<TableRow
								className={classes.tableRow}
								key={`result-table-row-${i}`}
								onClick={() => gotoResultDetail(result.id)}
							>
								<TableCell>{i + 1}</TableCell>
								<TableCell>{result.name}</TableCell>
								<TableCell>{result.tebaMerchantId}</TableCell>
								<TableCell>
									{result.deactivated === false ? 'Active' : 'Deactive'}
								</TableCell>
								<TableCell>{formatDate(result.createdAt)}</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						{!loadFinished && (
							<TableRow>
								<TableCell colSpan={3}>
									<Grid
										container
										justifyContent="center"
										direction="column"
										alignItems="center"
									>
										{loading && <LinearProgress className={classes.progress} />}
										<Button
											disabled={loading}
											variant="outlined"
											color="primary"
											onClick={() => setOffset(offset + 1)}
										>
											Load More
										</Button>
									</Grid>
								</TableCell>
							</TableRow>
						)}
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	)
}

export default DunningListTable
