import { useTranslation } from 'react-i18next'

import { API_InvoiceList, API_History } from '../../../../config/constants'

import formatDate from '../../../../utils/formatDate'
import { SortableTable } from '../../../../components/shared/SortableTable'
import { useActiveTab } from '../../../../hooks'

const InvoiceHistoryListTable = ({ inv_id, active }) => {
	const { t } = useTranslation()

	const loadData = useActiveTab(active)

	const headCells = [
		{ id: 'event', company: true, label: t('history.event') },
		{ id: 'eventDescription', label: t('history.eventDescription') },
		{ id: 'username', label: t('history.username') },
		{ id: 'timestamp', label: t('history.timestamp'), format: formatDate }
	]

	return loadData ? (
		<SortableTable
			apiUrl={`${API_InvoiceList}/${inv_id}${API_History}`}
			headCells={headCells}
			order={'timestamp'}
		/>
	) : (
		<></>
	)
}

export default InvoiceHistoryListTable
