import { useTranslation } from 'react-i18next'

import { API_TransactionList } from '../../../config/constants'

import { SortableTable } from '../../../components/shared/SortableTable'
import formatDate from '../../../utils/formatDate'
import { formatTaxPrice } from '../../../utils/formatCurrency'

const TransactionListTable = ({}) => {
	const { t } = useTranslation()

	const headCells = [
		{ id: 'id', label: 'No' },
		{ id: 'name', company: true, label: t('transaction.merchantName') },
		{ id: 'orgName', company: true, label: t('transaction.orgName') },
		{ id: 'totalPriceTaxed', label: t('transaction.totalPriceTaxed'), format: formatTaxPrice },
		{ id: 'transactionType', label: t('transaction.transactionType') },
		{ id: 'createdAt', label: t('transaction.date'), format: formatDate }
	]

	return (
		<>
			<SortableTable
				apiUrl={API_TransactionList}
				detailUrl="/transactions/detail"
				headCells={headCells}
			/>
		</>
	)
}

export default TransactionListTable
