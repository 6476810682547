import React, { useEffect, useState, useContext } from 'react';
import axios from '../../../utils/axios';
import { API_URL, API_UserList } from '../../../config/constants';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../../../context';
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { formatTaxPrice } from '../../../utils/formatCurrency'

const UserCompanies = (props) => {
    const { user_id } = props;
    const [companies, setCompanies] = useState([]);
    const { t } = useTranslation();
    const { showMessage } = useContext(SnackbarContext);

    useEffect(() => {
        fetchCompanies();
    }, [user_id]);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${API_URL}${API_UserList}/${user_id}/organisations`);
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching user companies', error);
            showMessage(t('errors.fetchFailed'), 'error');
        }
    };

    return (
        <Box mt={2} mb={4}>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('user.company.name')}</TableCell>
                            <TableCell>{t('user.company.purchaseLimit')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies.map((company) => (
                            <TableRow key={company.orgId}>
                                <TableCell>
                                    <a href={'../../organizations/detail/' + company.orgId}>
                                        {company.name}
                                    </a>
                                </TableCell>
                                <TableCell>{formatTaxPrice(company.purchaseLimit)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
};

export default UserCompanies;
