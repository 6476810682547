import { useTranslation } from 'react-i18next'
import { useState, useEffect, useContext } from 'react'

import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'

import {
	Box,
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	LinearProgress,
	InputBase,
	IconButton,
	TableSortLabel,
	Button,
	Grid,
	Collapse
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { ORDER_DIRS } from '../../../config/constants'
import { SnackbarContext } from '../../../context'

import useStyles from './SortableTable.styles'
import { formatEurocentsToEuro } from '../../../utils/formatCurrency'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

const RenderBody = ({ headCells, nestedHeadCells, result }) => {
	const [open, setOpen] = useState(false)

	return (
		<>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell key={headCell.id} width={`${100 / headCells.length}%`}>
						{headCell.id === 'merchantName' && (
							<IconButton size="small" onClick={() => setOpen(!open)}>
								{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
							</IconButton>
						)}

						{headCell.format
							? headCell.format(result[headCell.id])
							: result[headCell.id]}
					</TableCell>
				))}
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headCells.length}>
					<Collapse in={open} unmountOnExit>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell />
									{nestedHeadCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											width={`${100 / headCells.length}%`}
										>
											{headCell.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{result.months.map((item, i) => (
									<TableRow key={i}>
										<TableCell />
										{nestedHeadCells.map((headCell) => (
											<TableCell key={headCell.id}>
												{headCell.format
													? headCell.format(item[headCell.id])
													: item[headCell.id]}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

const SortableTableDownload = ({
	apiUrl,
	downloadUrl,
	headCells,
	order,
	refresh,
	setRefresh,
	pageSize = 150
}) => {
	const { showMessage } = useContext(SnackbarContext)
	const { t } = useTranslation()

	const classes = useStyles()

	const [items, setItems] = useState([])
	const [orderBy, setOrderBy] = useState(order || 'createdAt')
	const [orderDir, setOrderDir] = useState('desc')
	const [keyword, setKeyword] = useState('')
	const [offset, setOffset] = useState(0)
	const [loadFinished, setLoadFinish] = useState(false)

	const [triggerSearch, setTriggerSearch] = useState(true)

	const [loading, setLoading] = useState(true)

	const defaultCells = [
		{ id: 'totalPaAmount', label: t('report.totalPaAmount'), format: formatEurocentsToEuro },
		{ id: 'totalCpAmount', label: t('report.totalCpAmount'), format: formatEurocentsToEuro },
		{ id: 'totalRvAmount', label: t('report.totalRvAmount'), format: formatEurocentsToEuro },
		{ id: 'totalRfAmount', label: t('report.totalRfAmount'), format: formatEurocentsToEuro }
	]

	const fields = (headCells || defaultCells).map((item) => item.id)

	const nestedHeadCells = [
		{ id: 'month', label: t('report.month') },
		...(headCells || defaultCells)
	]
	headCells = [
		{ id: 'merchantName', label: t('report.merchant_name') },
		{ id: 'year', label: t('report.year') },
		...(headCells || defaultCells)
	]

	useEffect(() => {
		if (refresh) {
			setRefresh(false)
			resetSearch()
		}
	}, [refresh])

	useEffect(() => {
		if (triggerSearch) {
			loadMore()
			setTriggerSearch(false)
		}
	}, [triggerSearch])

	const groupData = (items) => {
		return items.reduce((previousValue, currentValue) => {
			const merchantIndex = previousValue.findIndex(
				(item) =>
					item.merchantName === currentValue.merchantName &&
					item.year === currentValue.year
			)

			const merchant =
				merchantIndex > -1
					? previousValue[merchantIndex]
					: {
							merchantName: currentValue.merchantName,
							year: currentValue.year,
							months: []
						}

			for (const field of fields) {
				merchant[field] = (merchant[field] || 0) + (currentValue[field] || 0)
			}
			merchant.months.push(currentValue)

			if (merchantIndex > -1) {
				previousValue[merchantIndex] = merchant
			} else {
				previousValue.push(merchant)
			}

			return previousValue
		}, [])
	}

	const loadMore = () => {
		setLoading(true)

		const params = snakecaseKeys(
			{
				limit: pageSize,
				offset,
				orderBy,
				orderDir: orderDir === 'asc' ? ORDER_DIRS.ASC : ORDER_DIRS.DESC,
				q: keyword
			},
			{ deep: true }
		)

		getData(params)
	}

	const getData = (params) => {
		axios
			.get(`${API_URL}${apiUrl}`, { params })
			.then(({ data }) => {
				data = groupData(camelcaseKeys(data, { deep: true }))

				let temp = items
				temp = temp.concat(data)

				setItems(temp)

				if (data.length < pageSize) setLoadFinish(true)

				setOffset(offset + 1)
			})
			.catch((e) => {
				showMessage(t('errors.500'), 'error')
				setLoadFinish(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const resetSearch = () => {
		setOffset(0)
		setItems([])
		setLoadFinish(false)

		setTriggerSearch(true)
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0].value
		if (value !== keyword) {
			setKeyword(value)
			resetSearch()
		}
	}

	const handleSortChange = (newOrderBy) => {
		if (newOrderBy === orderBy) {
			setOrderDir(orderDir === 'desc' ? 'asc' : 'desc')
		} else {
			setOrderDir('asc')
		}

		setOrderBy(newOrderBy)

		resetSearch()
	}

	const downloadList = async () => {
		setLoading(true)
		const res = await axios.get(`${API_URL}${downloadUrl}?q=${keyword}`, {
			responseType: 'blob',
			q: keyword
		})

		const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
		const blobUrl = URL.createObjectURL(blob)

		const link = document.createElement('a')

		link.href = blobUrl
		link.download = 'monthly_report.xlsx'

		document.body.appendChild(link)

		link.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window
			})
		)

		document.body.removeChild(link)

		setLoading(false)
	}

	return (
		<>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={2}>
				<Button onClick={downloadList} variant={'outlined'} disabled={loading}>
					{t('download')}
				</Button>
			</Box>
			<Paper
				elevation={1}
				component="form"
				className={classes.root}
				onSubmit={handleSearchFormSubmit}
			>
				<InputBase
					placeholder={t('general.searchWithKeyword')}
					inputProps={{ 'aria-label': 'search google maps' }}
				/>
				<IconButton type="submit" className="iconButton" aria-label="search">
					<SearchIcon />
				</IconButton>
			</Paper>
			<TableContainer component={Paper}>
				<Table aria-label="Companies Table">
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									sortDirection={orderBy === headCell.id ? orderDir : false}
									width={`${100 / headCells.length}%`}
								>
									{headCell.sortable === false ? (
										headCell.label
									) : (
										<TableSortLabel
											active={orderBy === headCell.id}
											direction={orderBy === headCell.id ? orderDir : 'asc'}
											onClick={() => {
												handleSortChange(headCell.id, headCell.company)
											}}
										>
											{headCell.label}
										</TableSortLabel>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((result, i) => (
							<RenderBody
								key={i}
								result={result}
								headCells={headCells}
								nestedHeadCells={nestedHeadCells}
							/>
						))}
					</TableBody>
					<TableFooter>
						{!loadFinished && (
							<TableRow>
								<TableCell colSpan={headCells.length + 1}>
									<Grid
										container
										justifyContent="center"
										direction="column"
										alignItems="center"
									>
										{loading && <LinearProgress className={classes.progress} />}
									</Grid>
								</TableCell>
							</TableRow>
						)}
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	)
}

export default SortableTableDownload
