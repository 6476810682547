import { useTranslation } from 'react-i18next'
import { Auth } from 'aws-amplify'
import { useNavigate, useLocation } from 'react-router'

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import {
	PieChart as DashbaordIcon,
	ExitToApp as SignOutIcon,
	AccountCircle as AccountIcon
} from '@material-ui/icons'

import EventIcon from '@material-ui/icons/Event'
import StorefrontIcon from '@material-ui/icons/Storefront'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

import useStyles from './NavContent.styles'
import { useEffect, useState } from 'react'

const NavContent = () => {
	const { t } = useTranslation()

	const [navItems, setNavItems] = useState([])

	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const items = []

		items.push(
			{
				title: t('dashboard'),
				icon: <DashbaordIcon fontSize="small" color={'inherit'} />,
				path: '/',
				exact: true
			},
			{
				title: t('purchases'),
				icon: <ShoppingCartIcon fontSize="small" color={'inherit'} />,
				path: '/purchases'
			},
			{
				title: t('transactions'),
				icon: <AccountBalanceIcon fontSize="small" color={'inherit'} />,
				path: '/transactions'
			},
			{
				title: t('invoices'),
				icon: <ReceiptIcon fontSize="small" color={'inherit'} />,
				path: '/invoices'
			},
			{
				title: t('reminders'),
				icon: <EventIcon fontSize="small" color={'inherit'} />,
				path: '/reminders'
			},
			{
				title: t('merchants'),
				icon: <StorefrontIcon fontSize="small" color={'inherit'} />,
				path: '/merchants'
			},
			{
				title: t('organizations'),
				icon: <StorefrontIcon fontSize="small" color={'inherit'} />,
				path: '/organizations'
			},
			{
				title: t('users'),
				icon: <AccountIcon fontSize="small" color={'inherit'} />,
				path: '/users'
			},
			{
				title: t('admin'),
				icon: <AccountIcon fontSize="small" color={'inherit'} />,
				path: '/admins'
			},
			{
				title: t('sign_out'),
				icon: <SignOutIcon fontSize="small" color={'inherit'} />,
				onClick: () => {
					Auth.signOut()
					return false
				}
			}
		)

		setNavItems(items)
	}, [t])

	const goTo = (path) => {
		navigate(path)
		return false
	}

	const classes = useStyles()

	const active = (exact, path) => {
		return (
			(exact && location.pathname === path) || (!exact && location.pathname.startsWith(path))
		)
	}

	return (
		<List classes={{ root: classes.list }}>
			{navItems.map(({ title, icon, path, exact, onClick }, i) => {
				return (
					<ListItem
						button
						key={`nav-item-${i}`}
						onClick={
							onClick
								? (e) => {
										e.preventDefault()
										onClick(e)
									}
								: path
									? (e) => {
											e.preventDefault()
											goTo(path)
										}
									: null
						}
						selected={active(exact, path)}
						classes={{ root: classes.root, selected: classes.selected }}
					>
						<ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
						<ListItemText classes={{ primary: classes.listItemText }} primary={title} />
					</ListItem>
				)
			})}
		</List>
	)
}

export default NavContent
