import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from '@material-ui/core'
import TableCellWithTooltip from '../TableCellWithTooltip'

const DetailRow = ({ name, value, tooltip }) => {
	const { t } = useTranslation()

	return (
		<TableRow>
			<TableCell variant={'head'} width={'40%'}>
				{tooltip ? <TableCellWithTooltip title={name} tooltip={tooltip} /> : t(name)}
			</TableCell>
			<TableCell item xs={6} sm={6}>
				{value}
			</TableCell>
		</TableRow>
	)
}

export default DetailRow
