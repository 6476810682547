import { useContext, useEffect, useState } from 'react'
import { SnackbarContext } from '../../../context'
import { useTranslation } from 'react-i18next'
import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import {
	Box,
	Button,
	Grid,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography
} from '@material-ui/core'

const TableList = ({ apiUrl, headCells, title }) => {
	const { showMessage } = useContext(SnackbarContext)
	const { t } = useTranslation()

	const [loading, setLoading] = useState(true)
	const [items, setItems] = useState([])

	const params = {
		'end-date': new Date().toISOString().split('T')[0]
	}

	useEffect(() => {
		axios
			.get(`${API_URL}${apiUrl}`, {
				params: params
			})
			.then(({ data }) => {
				setItems(camelcaseKeys(data, { deep: true }))
			})
			.catch((e) => {
				showMessage(t('errors.500'), 'error')
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	return (
		<>
			<Box mb={4}>
				<Box my={2}>
					<Typography>{title}</Typography>
				</Box>

				<TableContainer component={Paper}>
					<Table aria-label="Companies Table">
						<TableHead>
							<TableRow>
								{/*<TableCell width={'10%'}>No</TableCell>*/}
								{headCells.map((headCell) => (
									<TableCell width={`${100 / headCells.length}%`}>
										{headCell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((result, i) => (
								<TableRow key={`result-table-row-${i}`}>
									{headCells.map((headCell) => (
										<TableCell key={headCell.id}>
											{headCell.format
												? headCell.format(result[headCell.id])
												: result[headCell.id]}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>

						{loading && (
							<TableFooter>
								<TableRow>
									<TableCell colSpan={headCells.length + 1}>
										<Grid
											container
											justifyContent="center"
											direction="column"
											alignItems="center"
										>
											<LinearProgress
												style={{
													width: '100%'
												}}
											/>
										</Grid>
									</TableCell>
								</TableRow>
							</TableFooter>
						)}
					</Table>
				</TableContainer>
			</Box>
		</>
	)
}

const WeeklyReports = ({ active }) => {
	const { showMessage } = useContext(SnackbarContext)
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)

	const download = async () => {
		setLoading(true)
		try {
			const { data } = await axios.get(`${API_URL}/weekly-report/xlsx`, {
				responseType: 'blob'
			})

			const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
			const blobUrl = URL.createObjectURL(blob)

			const link = document.createElement('a')

			link.href = blobUrl
			link.download = 'monthly_report.xlsx'

			document.body.appendChild(link)

			link.dispatchEvent(
				new MouseEvent('click', {
					bubbles: true,
					cancelable: true,
					view: window
				})
			)

			document.body.removeChild(link)
		} catch (e) {
			showMessage(t('errors.500'), 'error')
		}

		setLoading(false)
	}

	return (
		<>
			{active && (
				<>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
						py={2}
					>
						<Button onClick={download} variant={'outlined'} disabled={loading}>
							{t('download')}
						</Button>
					</Box>

					<TableList
						title={'All Purchases'}
						apiUrl={'/weekly-report/duplicates'}
						headCells={[
							{ id: 'merchantName', label: t('report.rowName') },
							{ id: 'purchaseVolume', label: t('report.purchaseVolume') },
							{ id: 'capturedVolume', label: t('report.capturedVolume') }
						]}
					/>
					<TableList
						title={'Unique debtors'}
						apiUrl={'/weekly-report/unique'}
						headCells={[
							{ id: 'merchantName', label: t('report.rowName') },
							{ id: 'purchaseVolume', label: t('report.purchaseVolume') },
							{ id: 'capturedVolume', label: t('report.capturedVolume') }
						]}
					/>
					<TableList
						title={'Unique Debtors by Merchants'}
						apiUrl={'/weekly-report/merchant-report'}
						headCells={[
							{ id: 'merchantName', label: t('report.rowName') },
							{ id: 'purchaseVolume', label: t('report.purchaseVolume') },
							{ id: 'capturedVolume', label: t('report.capturedVolume') }
						]}
					/>
					<TableList
						title={'All Purchases Monthly'}
						apiUrl={'/weekly-report/monthly-duplicates'}
						headCells={[
							{ id: 'merchantName', label: t('report.rowName') },
							{ id: 'purchaseVolume', label: t('report.purchaseVolume') },
							{ id: 'capturedVolume', label: t('report.capturedVolume') }
						]}
					/>
				</>
			)}
		</>
	)
}

export default WeeklyReports
