import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './layouts/Main'

import Home from './Home'
import Login from './Login'
import { TransactionList, TransactionDetail } from './pages/transaction'
import {
	// MonthlyVolumeReport,
	// MonthlyInvoiceVolumeReport,
	UncapturedPurchasesReport
} from './pages/statistics'
import { InvoiceList, InvoiceDetail } from './pages/invoice'
import { PurchaseList, PurchaseDetail } from './pages/purchase'
import { DunningList } from './pages/dunning'
import { OrganizationList, OrganizationDetail } from './pages/organization'
import { ReminderList } from './pages/reminder'
import { MerchantList, MerchantAdd, MerchantDetail } from './pages/merchant'
import { Admin } from './pages/admin'
import { Profile } from './pages/profile'
import ReminderDetail from './pages/reminder/ReminderDetail'
import UserList from './pages/user'
import UserDetail from 'pages/user/UserDetail'
import { RequireAuth } from './utils/RequireAuth'
import OrganizationAdd from './pages/organization/OrganizationAdd'

const AppRouter = () => (
	<Routes>
		<Route exact path="/login" element={<Login />} />

		<Route element={<Main />}>
			<Route element={<RequireAuth />}>
				<Route path="/" element={<Home />} />

				{/*
				<RouteWithLayout layout={Main} exact path="/m" component={MonthlyVolumeReport} />
				<RouteWithLayout layout={Main} exact path="/m" component={MonthlyInvoiceVolumeReport} />
				<RouteWithLayout layout={Main} exact path="/m" component={UncapturedPurchasesReport} />
				*/}
				<Route path="/m" element={<UncapturedPurchasesReport />} />

				<Route path="/transactions" element={<TransactionList />} />
				<Route path="/transactions/detail/:id" element={<TransactionDetail />} />

				<Route path="/invoices" element={<InvoiceList />} />
				<Route path="/invoices/detail/:id" element={<InvoiceDetail />} />

				<Route path="/purchases" element={<PurchaseList />} />
				<Route path="/purchases/detail/:id" element={<PurchaseDetail />} />

				<Route path="/dunnings" element={<DunningList />} />

				<Route path="/reminders" element={<ReminderList />} />
				<Route path="/reminders/detail/:id" element={<ReminderDetail />} />

				<Route path="/organizations" element={<OrganizationList />} />
				<Route path="/organizations/detail/:id" element={<OrganizationDetail />} />
				<Route path="/organizations/add" element={<OrganizationAdd />} />

				<Route path="/users" element={<UserList />} />
				<Route path="/users/detail/:id" element={<UserDetail />} />

				<Route path="/merchants" element={<MerchantList />} />
				<Route path="/merchants/add" element={<MerchantAdd />} />
				<Route path="/merchants/edit/:id" element={<MerchantAdd />} />
				<Route path="/merchants/detail/:id" element={<MerchantDetail />} />

				<Route path="/admins" element={<Admin />} />
				<Route path="/profile" element={<Profile />} />
			</Route>
		</Route>
	</Routes>
)

export default AppRouter
