import { Button } from '@material-ui/core'
import formatDate from '../../../utils/formatDate'
import { useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { API_MerchatList, API_URL } from '../../../config/constants'
import camelcaseKeys from 'camelcase-keys'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import DetailTable from '../../../components/shared/DetailTable/DetailTable'
import { DetailRow } from '../../../components/shared/DetailTable'
import { useActiveTab } from '../../../hooks'
import { formatEuro, formatEurocentsToEuro } from '../../../utils/formatCurrency'

const riskLevels = {
	1: 'low',
	2: 'neutral',
	3: 'high'
}

const MerchantInfos = ({ mer_id, active }) => {
	const loadData = useActiveTab(active)
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [detail, setDetail] = useState({
		id: '',
		activateCustomTheme: '',
		ageLimitForUnconfirmedUsers: '',
		bic: '',
		billingCity: '',
		billingCountryCode: '',
		billingPcode: '',
		billingStreet: '',
		billingStreetSecondRow: '',
		businessId: '',
		confirmationRequired: '',
		contactLanguage: '',
		country: '',
		creditLine: '',
		currency: '',
		deactivated: '',
		email: '',
		enableOrgLists: '',
		expectedRevenue: '',
		iban: '',
		invoiceWithinWorkDays: '',
		invoicingDelay: '',
		invoicingFee: '',
		createdAt: '',
		mobile: '',
		name: '',
		noPersonCreditRestrictions: '',
		paymentPeriod: '',
		pendingLimit: '',
		preventInvoicing: '',
		preventPending: '',
		regOffice: '',
		registrationNumber: '',
		sendInvoiceReminders: '',
		shouldRestrictOptionalFields: '',
		shouldSendAsGrossInvoice: '',
		tebaMerchantId: '',
		unconfirmedUserPrivilegesRestrictions: '',
		shouldReceiveInvoices: '',
		vatId: '',
		website: '',
		amountMismatchTolerance: ''
	})

	useEffect(() => {
		if (!mer_id || !loadData) return
		;(async () => {
			try {
				let res = await axios.get(`${API_URL}${API_MerchatList}/${mer_id}`)
				let detailData = res.data
				setDetail(camelcaseKeys(detailData, { deep: true }))
			} catch (error) {
				console.log('loading error', error)
			}
		})()
	}, [mer_id, loadData])

	const handleClickEdit = (id) => {
		navigate(`/merchants/edit/${id}`)
	}

	return (
		<>
			<DetailTable title={'merchant.country_header'}>
				<DetailRow name={'merchant.country'} value={detail.country} />
				<DetailRow name={'merchant.currency'} value={detail.currency} />
				<DetailRow name={'merchant.language'} value={detail.contactLanguage} />
			</DetailTable>

			<DetailTable title={'general.basicInformation'}>
				<DetailRow
					name={'general.creationDate'}
					value={detail.createdAt !== '' ? formatDate(detail.createdAt) : ''}
				/>
				<DetailRow name={'merchant.detail.name'} value={detail.name} />
				<DetailRow name={'merchant.detail.businessId'} value={detail.businessId} />
				<DetailRow name={'merchant.detail.vatId'} value={detail.vatId} />
				<DetailRow
					name={'merchant.detail.deactivated'}
					value={
						detail.deactivated == false && detail.deactivated !== ''
							? 'Active'
							: 'Deactive'
					}
				/>
				<DetailRow name={'merchant.detail.email'} value={detail.email} />
				<DetailRow name={'merchant.detail.mobile'} value={detail.mobile} />
				<DetailRow name={'merchant.detail.website'} value={detail.website} />
				<DetailRow name={'merchant.detail.regOffice'} value={detail.regOffice} />
				<DetailRow
					name={'merchant.detail.registrationNumber'}
					value={detail.registrationNumber}
				/>
			</DetailTable>

			<DetailTable title={'merchant.detail.information'}>
				<DetailRow
					name={'merchant.detail.creditLine'}
					value={formatEurocentsToEuro(detail.creditLine)}
				/>
				<DetailRow
					name={'merchant.detail.expectedRevenue'}
					value={detail.expectedRevenue}
				/>
				<DetailRow
					name={'merchant.detail.riskLevel'}
					value={t(`risks.${riskLevels[detail.riskLevel]}`)}
				/>
			</DetailTable>

			<DetailTable title={'merchant.detail.invoicing'}>
				<DetailRow name={'merchant.detail.iban'} value={detail.iban} />
				<DetailRow name={'merchant.detail.bic'} value={detail.bic} />
				<DetailRow name={'merchant.detail.tebaMerchantId'} value={detail.tebaMerchantId} />
				<DetailRow
					name={'merchant.detail.preventInvoicing'}
					tooltip={'merchant.detail.preventInvoicing.info'}
					value={detail.preventInvoicing ? 'Yes' : 'No'}
				/>
				<DetailRow
					name={'merchant.detail.sendInvoiceReminders'}
					value={detail.sendInvoiceReminders ? 'Yes' : 'No'}
				/>

				<DetailRow
					name={'merchant.detail.shouldReceiveInvoices'}
					value={detail.shouldReceiveInvoices ? 'Yes' : 'No'}
				/>
				<DetailRow
					name={'merchant.detail.invoicingEmail'}
					tooltip={'merchant.detail.invoicingEmail.info'}
					value={detail.invoicingEmail}
				/>
			</DetailTable>

			<DetailTable title={'merchant.detail.defaultPaymentPeriod'}>
				<DetailRow name={'merchant.detail.paymentPeriod'} value={detail.paymentPeriod} />
			</DetailTable>

			<DetailTable title={'merchant.detail.otherParameters'}>
				<DetailRow
					name={'merchant.detail.shouldRestrictOptionalFields'}
					value={detail.shouldRestrictOptionalFields ? 'Yes' : 'No'}
				/>
				<DetailRow
					name={'merchant.detail.preventPending'}
					value={detail.preventPending ? 'Yes' : 'No'}
				/>
				<DetailRow
					name={'merchant.detail.noPersonCreditRestrictions'}
					value={detail.noPersonCreditRestrictions ? 'Yes' : 'No'}
				/>

				<DetailRow name={'merchant.detail.timer'} value={detail.timer} />
				<DetailRow
					name={'merchant.detail.amountMismatchTolerance'}
					value={
						detail.amountMismatchTolerance
							? formatEuro(detail.amountMismatchTolerance / 100)
							: ''
					}
				/>
			</DetailTable>

			<Button
				variant="contained"
				color="primary"
				onClick={() => handleClickEdit(detail['id'])}
			>
				{t('edit')}
			</Button>
		</>
	)
}

export default MerchantInfos
