import React, { useContext, useEffect } from 'react'

import '@aws-amplify/ui-react/styles.css'

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { Box, Toolbar } from '@material-ui/core'
import AppHeader from './layouts/Main/components/AppHeader'
import Logo from './layouts/Main/components/HeaderContent/Logo'
import { useLocation, useNavigate } from 'react-router'
import { UserContext } from './context'

import useStyles from './Login.styles'

const Login = () => {
	const classes = useStyles()

	const { setUser } = useContext(UserContext)

	const navigate = useNavigate()
	const location = useLocation()
	const { route } = useAuthenticator((context) => [context.route])
	const { user } = useAuthenticator((context) => [context.user])

	let from = location.state?.from?.pathname || '/'

	useEffect(() => {
		if (route === 'authenticated') {
			const {
				username,
				attributes: { email },
				signInUserSession: {
					idToken: {
						payload: { 'cognito:groups': groups }
					}
				}
			} = user

			console.log('data', {
				username,
				email,
				groups
			})

			setUser({
				username,
				email,
				groups
			})

			navigate(from, { replace: true })
		}
	}, [route, navigate, from])

	return (
		<>
			<AppHeader>
				<Toolbar>
					<Logo />
				</Toolbar>
			</AppHeader>
			<Box className={classes.wrapper} my={6}>
				<Authenticator />
			</Box>
		</>
	)
}

export default Login
