import { useState } from 'react'

import { Box, Typography, Breadcrumbs, Link } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import DunningListTable from './DunningListTable'
import Error from '../../../layouts/Main/components/Error'
import { useTranslation } from 'react-i18next'

const DunningList = () => {
	const { t } = useTranslation()
	const [error, setError] = useState(false)

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('dunningList')}
				</Typography>
				{/* <Button
					variant="contained"
					color="primary"
					onClick={handleAddCompanyClick}
					className={classes.companyAddButton}
				>
					Add Merchant
				</Button> */}
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link color="inherit" variant={'subtitle2'} href="/">
					{t('dashboard')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('dunnings')}
				</Typography>
			</Breadcrumbs>

			{error ? <Error /> : <DunningListTable setError={setError} />}
		</>
	)
}

export default DunningList
