import { useTranslation } from 'react-i18next'
import { API_OrganizationList } from '../../../config/constants'

import { FormControlLabel, Switch, TableCell } from '@material-ui/core'

import { SortableTable } from '../../../components/shared/SortableTable'
import { useNavigate } from 'react-router'
import { useState } from 'react'

const OrganizationListTable = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const [isWhitelisted, setIsWhitelisted] = useState(false)

	const headCells = [
		{ id: 'name', company: true, label: t('organization.name') },
		{ id: 'businessId', label: t('organization.businessId') },
		{ id: 'vatId', label: t('organization.vatId') },
		{ id: 'tebaClientId', label: t('organization.tebaClientId') }
	]

	const gotoResultDetail = (id) => {
		navigate(`/organizations/detail/${id}`)
	}

	const renderBodyCells = (result) => {
		return (
			<>
				<TableCell onClick={() => gotoResultDetail(result.id)}>{result.name}</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>
					{result.businessId}
				</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>{result.vatId}</TableCell>
				<TableCell onClick={() => gotoResultDetail(result.id)}>
					{result.tebaClientId === null ? '' : result.tebaClientId}
				</TableCell>
			</>
		)
	}

	const AdditionalInputs = ({ isActive, setIsActive }) => {
		const handleChange = (event) => {
			setIsActive(event.target.checked)
		}

		return (
			<FormControlLabel
				control={
					<Switch
						checked={isActive}
						onChange={handleChange}
						color="primary"
						name="whitelisted"
					/>
				}
				label="Whitelisted"
			/>
		)
	}

	return (
		<SortableTable
			apiUrl={API_OrganizationList}
			detailUrl="/organizations/detail"
			headCells={headCells}
			renderBodyCells={renderBodyCells}
			additionalInputs={
				<AdditionalInputs isActive={isWhitelisted} setIsActive={setIsWhitelisted} />
			}
			additionalParams={{ whitelisted: isWhitelisted }}
		/>
	)
}

export default OrganizationListTable
