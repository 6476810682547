import { Box, Button, Container, Paper, TextField, Typography } from '@material-ui/core'
import React from 'react'

const OrganizationForm = ({ formData, handleChangeFormData, handleSubmit }) => {
	return (
		<Container maxWidth={'md'}>
			<Box mt={4} mb={4} component={'form'} onSubmit={handleSubmit}>
				<Paper variant="outlined" square>
					<Box p={2}>
						<Typography variant="h4" component="h4" gutterBottom={true}>
							Basic details
						</Typography>
						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('businessId')}
								label="Business ID"
								value={formData.businessId}
							/>
						</Box>

						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('name')}
								label="Name"
								required
								value={formData.name}
							/>
						</Box>

						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('billingPcode')}
								label="Postal Code"
								required
								value={formData.billingPcode}
							/>
						</Box>

						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('street')}
								label="Street"
								value={formData.street}
							/>
						</Box>

						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('billingCity')}
								label="City"
								value={formData.billingCity}
							/>
						</Box>

						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('billingCountryCode')}
								label="Country"
								value={formData.billingCountryCode}
							/>
						</Box>

						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('vatId')}
								label="VAT id"
								value={formData.vatId}
							/>
						</Box>

						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('email')}
								label="Email"
								value={formData.email}
							/>
						</Box>
						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('mobile')}
								label="Mobile"
								value={formData.mobile}
							/>
						</Box>
						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('contactLanguage')}
								label="Contact language"
								value={formData.contactLanguage}
							/>
						</Box>

						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('creditLimit')}
								label="Credit Limit"
								value={formData.creditLimit}
							/>
						</Box>
						<Box mt={1}>
							<TextField
								fullWidth
								onChange={handleChangeFormData('regOffice')}
								label="Office"
								value={formData.regOffice}
							/>
						</Box>
						<Box mt={4}>
							<Button type={'submit'} color="secondary" variant="contained">
								Submit
							</Button>
						</Box>
					</Box>
				</Paper>
			</Box>
		</Container>
	)
}

export default OrganizationForm
