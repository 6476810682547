const formatCurrencyToNumber = (number) => {
	if (number) {
		return parseInt(number.toString() + '000')
	} else {
		return ''
	}
}

const formatEuro = (number) => {
	return number
		? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number)
		: ''
}

const formatCurrency = (number) => {
	return formatEuro(formatCurrencyToNumber(number))
}

const formatEurocentsToEuro = (number) => {
	if (number) {
	return formatEuro(parseInt(number) / 100)
} else return "0 €" 
}

const formatQuote = (number) => {
	if (number) {
		return (number = parseFloat(number).toFixed(2))
	} else {
		return '0'
	}
}


const formatPDValue = (number) => {
	if (number) {
		return (number = parseFloat(number).toFixed(5))
	} else {
		return '0'
	}
}

const formatTaxPrice = (number) => {
	if (number) {
		return (number = (parseFloat(number) / 100).toFixed(2) + ' EUR')
	} else {
		return '0 EUR'
	}
}

const formatInvoiceType = (string) => {
	if (string === 'INV01') {
		return 'Basic'
	} else {
		return 'Refund'
	}
}

export default formatCurrency
export {
	formatTaxPrice,
	formatInvoiceType,
	formatQuote,
	formatCurrencyToNumber,
	formatEurocentsToEuro,
	formatEuro,
	formatPDValue
}
